.changelog {
    background-color: white;
    padding: 20px 40px;

    @media @dx-mobile-sm {
        padding: 5px;
    }

    .date {
        padding-bottom: 25px;
    }

    .title {
        font-weight: 700;
    }

    .badge {
        border-radius: 4px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .filmscheduler {
        .badge {
            background-color: @dx-secondary;
        }
    }

    .mediabase {
        .badge {
            background-color: purple;
        }
    }

    .bugs {
        .badge {
            background-color: @brand-danger;
        }
    }

    .partneradmin {
        .badge {
            background-color: @dx-primary;
        }
    }

    .useradmin {
        .badge {
            background-color: @brand-warning;
        }
    }
}
