.input-popover-outer {
    z-index: @dx-z-popover;
    position: inherit;

    .input-popover {
        position: absolute;
        height: auto;
        bottom: 30px;
        padding: 5px;
        border-radius: 2px;
        background-color: white;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.175);
        z-index: @dx-z-popover;

        @media @dx-mobile-sm {
            width: auto;
            // width: 100% !important;
        }

        &:after,
        &:before {
            top: 100%;
            left: 20px;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-top-color: white;
            border-width: 5px;
            margin-left: -5px;
        }

        &:before {
            border-top-color: @dx-gray-lighter;
            border-width: 6px;
            margin-left: -6px;
        }
    }

    .input-popunder {
        position: absolute;
        height: 222px;
        top: 30px;
        padding: 5px;
        border-radius: 2px;
        background-color: white;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.175);
        z-index: @dx-z-popover;

        @media @dx-mobile-sm {
            width: auto;
        }
    }
}
