@mediabase-bg: #1b2633;

.mediabase {
    background-color: @mediabase-bg;
    padding-bottom: 139px;
    .mediabase-search-and-results {
        @posterHeight: 220px;
        @posterWidth: (115/175) * @posterHeight;

        padding: 30px 2% 50px;
        min-height: 100%;

        .mediabase-search-box-container {
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .mediabase-filters-container {
            max-width: 1000px;
            margin: 0 auto;
            margin-bottom: 30px;
            text-align: center;
        }

        .mediabase-results-container {
            text-align: center;
            // display: flex;
            // flex-flow: row wrap;
            // justify-content: space-between;
            //
            // &:after {
            // 	content: "";
            // 	flex: auto;
            // }

            .mediabase-list-item {
                display: inline-block;
                margin: 0 15px 20px 10px;
                position: relative;
                height: @posterHeight + 50px;
                width: @posterWidth;
                vertical-align: middle;
                text-align: left;
                cursor: pointer;
                background-color: @mediabase-bg;

                .mediabase-list-item-inner {
                    background-color: @mediabase-bg;
                    z-index: 0;

                    &:hover {
                        box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.2);
                        position: absolute;
                        height: auto;
                        z-index: 100;

                        .mediabase-list-item-text-box .mediabase-list-title {
                            text-overflow: inherit;
                            white-space: normal;
                            overflow: visible;
                        }
                    }
                }

                div.mediabase-list-item-img-box {
                    img.mediabase-list-img {
                        height: @posterHeight;
                        width: @posterWidth;
                    }
                }

                .mediabase-list-health {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 30px;
                    height: 30px;

                    svg {
                        width: 100%;
                        height: 100%;

                        polygon {
                            fill: @dx-danger-light;
                        }

                        text {
                            font-family: proxima-nova, 'Helvetica Neue', Helvetica, Arial, sans-serif;
                            fill: white;
                            font-weight: 900;
                            font-size: 40px;
                        }
                    }
                }

                .mediabase-list-item-text-box {
                    width: 100%;
                    padding: 8px 3px 3px 3px;
                    color: white;

                    .mediabase-list-title {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    p.mediabase-list-firstnight {
                        margin: 5px 0 0;
                        color: #666;
                    }

                    p.mediabase-list-local {
                        margin: 5px 0 0;
                        color: @dx-warning;
                    }
                }
            }
        }
    }
}

.mediabase-list-productions-poster {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
    img {
        max-height: 221px;
    }
}

.mediabase-edit-production {
    .header-poster-flex {
        display: flex;

        @media @dx-mobile-md {
            flex-wrap: wrap;
        }

        .poster {
            flex: 1 0 auto;
        }

        .settings {
            flex: 1 1 auto;
        }
    }
}

// Clickable poster with hover effect overlay for EditMainPoster.jsx
.mediabase-edit-main-poster {
    cursor: pointer;
    position: relative;

    div.mediabase-main-poster-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        background-color: black;
        pointer-events: none;
        text-align: center;
        color: white;

        i {
            font-size: 40px;
            color: white;
            position: absolute;
            bottom: 50%;
            left: calc(~'50% - 18px');
        }

        p {
            position: absolute;
            bottom: calc(~'50% - 35px');
            left: calc(~'50% - 35px');
        }
    }

    &:hover {
        > div {
            opacity: 0.7;
        }
    }
}

// When selecting between the available posters in EditMainPosterSelect.jsx
img.mediabase-edit-main-poster-select-poster {
    width: 100px;
    cursor: pointer;
    border: 2px solid transparent;
    &:hover,
    &:focus,
    &:hover:focus {
        border: 2px solid @dx-secondary;
    }
}

// for mediabase/components/ListVersions.jsx
.mediabase-version-list-item {
    // border: 1px solid @dx-gray-lighter;
    padding: 10px;
    display: flex;
    align-items: center;
    // margin-bottom: 10px;

    .col-title {
        flex: 0 0 60%;
    }
    .col-edi {
        flex: 0 0 40%;
    }
    h5 {
        font-size: 12px;
        font-weight: bold;
        color: @dx-gray-darker;
        margin: 0;
        padding: 0;
    }
}

// List of productions matching search string in MoveVersion
p.mediabase-move-version-search-result {
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        background-color: @dx-gray-lighter;
    }

    &.selected {
        background-color: @dx-primary;
    }
}

.mediabase-asset-container {
    padding: 0 15px 15px 0;

    .mediabase-asset-tag {
        padding: 5px;
        background-color: @dx-gray-lighter;
        border-radius: 6px;
        margin-right: 7px;
        font-size: 13px;

        &.active {
            background-color: @dx-primary;
        }
    }

    img {
        max-width: 350px;
        max-height: 250px;
        width: auto;
        height: auto;
        cursor: pointer;
    }
}

.mediabase-download-asset-link {
    color: @dx-secondary;
    cursor: pointer;
    margin: 0 10px;
    font-weight: bold;
    display: block;
}

.mediabase-delete-asset-link {
    color: @dx-danger;
    cursor: pointer;
    margin: 0 10px;
}

.mediabase-poster-with-overlay-buttons {
    height: 250px;
    position: relative;

    .menu-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        text-align: center;
        padding-top: 20px;
        display: none;

        &.mobile-hover-activated {
            display: block;
        }
    }

    &:hover {
        .menu-overlay {
            display: block;
        }
    }
}

.mediabase-asset-uploader {
    min-height: 500px;
    border: 3px solid white;
    border-radius: @dx-border-radius;
}

.mediabase-asset-uploader-active {
    border: 3px dotted @dx-gray-lighter;
}

// for wrong filetypes
.mediabase-asset-uploader-error {
    border: 3px dotted @dx-danger;
}

.mediabase-type-filter {
    background-color: #315873;
    text-align: center;

    h4 {
        display: inline-block;
        text-transform: uppercase;
        color: white;
        margin: 15px 10px 0 10px;
        padding: 0 15px;
        margin-top: 10px;
        font-size: 18px;
        cursor: pointer;
    }

    h4.type-selected {
        border-bottom: 6px solid @dx-secondary;
    }
}

.mediabase-loading {
    text-align: center;
    font-size: 22px;
    padding: 5px 5px 15px;
}
