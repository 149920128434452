.bar-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    background-color: #f6f7f8;
    border-top: 1px solid #dadfe3;
    border-bottom: 1px solid #dadfe3;

    // Paddings and margins will be overridden if Bar is inside a card so it
    // better suits cards.
    padding-left: 15px;
    padding-right: 15px;
    // margin-top: 10px;
    margin-bottom: 10px;

    // If inside a card, we need to do a trick with the margins so it uses full
    // width of the card:
    .cards-fill-sides();
}

.bar-component-with-collapsed-content {
    margin: 0;
    margin-bottom: -1px; //slightly overlaps the Bar component on top if stacked.
}

// override bar-component.less -> remove top border
.bar-component-without-border {
    .bar-component {
        border: none;
    }
}
