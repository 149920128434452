.register-body {
    background: #f3f4f8;
}

#register-body {
    //border-top: 10px solid #94CF56;
}

.register-container {
    width: 700px;
    margin: 6% auto 0 auto;
    background: #fff;
    border: 1px solid #ced0d6;

    h1 {
        font-weight: 600;
        color: #94cf56;
    }

    h3 {
        color: #50c0e2;
        font-size: 18px;
        font-weight: 600;
    }

    p {
        font-size: 18px;
        color: #81838d;
    }
}

.register-form {
    padding: 20px;

    input {
        border-radius: 1px !important;
    }
}

.register-content {
    // background: url('../../../../img/mesh1.png') #404e5a;
    //border-bottom: 40px solid #94CF56;
    padding: 30px 0;
    text-align: center;
    border: 2px solid #fff;

    p {
        color: #fff;
    }
}

.signin-wrapper {
    text-align: center;
}

input#signin {
    background: #50c0e2;
    border: none;
    display: inline-block;
    padding: 23px 40px;
    font-size: 18px;
    color: #fff;
    margin: 10px 0 25px 0;
}

.register-secondary {
    margin: 10px;
}

.register-error {
    display: none;
    color: red;
    font-weight: 500;
}

@media (max-width: 480px) {
    .register-container {
        width: 100%;
    }
}
