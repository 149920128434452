.event-details-order-bill-line {
    background-color: #f6f7f8;
    border-top: 1px solid #dadfe3;
    border-bottom: 1px solid #dadfe3;
    margin-bottom: -1px;

    h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media @dx-mobile-sm {
            font-size: 14px;
        }
    }
}

.event-details-order-bill-line-content {
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    -webkit-transition: height 0.3s;
    transition: height 0.3s;
    height: 0;
    overflow: hidden;

    @media @dx-mobile-xs {
        font-size: 12px;
        margin-left: -10px;
        margin-right: -10px;
    }
}

.event-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .heading {
        display: flex;
        align-items: center;
        width: 80%;

        .back-button {
            padding: 20px 30px;
            font-size: 30px;
            // background-color: @dx-primary;
            border-right: 1px solid @dx-black-light;

            &:hover {
                color: @dx-secondary;
            }

            @media @dx-mobile-sm {
                padding: 5px 15px;
            }
        }

        .title-container {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            white-space: nowrap;
            overflow: hidden;

            @media @dx-mobile-sm {
                padding-left: 15px;
            }

            p {
                text-transform: uppercase;
                color: @dx-black-medium;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;

                @media @dx-mobile-sm {
                    font-size: 12px;
                }
            }

            h3 {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;

                @media @dx-mobile-sm {
                    font-size: 16px;
                }
            }
        }
    }

    .date-location {
        margin: 0;
        color: @dx-black-medium;
        padding-left: 20px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media @dx-mobile-sm {
            padding-left: 10px;
        }
    }

    .settings {
        padding-right: 20px;

        @media @dx-mobile-sm {
            padding-right: 5px;
        }
    }
}

.events-list-renter {
    color: @dx-black-medium;
    margin: 0 8px 0 25px;

    @media @dx-mobile-md {
        margin: 0 5px 0 0;
    }
}

.participants-menu,
.transactions-menu {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .filters {
        display: flex;
        flex-basis: 500px;
        flex-direction: column;
        .search {
            display: flex;
            align-items: baseline;
            i {
                margin-right: 10px;
                color: @dx-gray-dark;
            }
        }
    }
}

.resource-section {
    .resource-section-header {
        background-color: @dx-gray-lightest;
        padding: 10px 25px;
    }

    .resource-header {
        color: @dx-gray-dark;
        padding: 10px 5px;
    }

    .resource-item {
        padding: 10px 5px;
    }
}

.note {
    textarea {
        height: 300px !important;
    }
}

// EventDetailsTicketSalesOverviewHeader.jsx
.ticketsales {
    .stats {
        padding: 15px 30px 30px 15px;

        &.extra-padding-left {
            padding-left: 30px;
        }

        @media @dx-mobile-sm {
            padding: 15px 10px 20px 10px;

            h3 {
                font-size: 18px;
                margin-top: 10px;
            }
        }
    }
    .graph-header {
        margin-left: -15px;
        margin-right: -15px;
        padding: 5px 20px 25px;

        @media @dx-mobile-sm {
            padding: 0px 10px 5px;
        }
    }
}

.ticketsale-settings-overlay-container {
    position: relative;

    .overlay {
        position: absolute;
        background-color: @dx-background;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.8;
        display: none;
    }

    &.disabled {
        .overlay {
            display: block;
        }
    }
}

.date-begin,
.date-end {
    margin: 0;

    @media @dx-mobile-md {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

.date-begin {
    @media @dx-mobile-md {
        &::after {
            content: ' - ';
        }
    }
}

.order-label {
    margin-bottom: 10px;

    span {
        padding: 6px 8px;

        &.orange {
            background-color: @dx-yellow;
        }
    }
}

.old-events-separator {
    padding: 15px 15px 15px;
    margin-left: -15px;
    margin-right: -15px;
    background-color: @dx-gray-lightest;
    font-size: 20px;
}

.reservation-inputs {
    display: flex;

    label {
        // white-space: nowrap;
    }

    .reservation-input {
        input {
            text-align: center;
        }
    }
}

.sales-bar {
    position: relative;
    height: 25px;

    .hundred-percent,
    .reserved,
    .sold {
        position: absolute;
        top: 0;
        height: 10px;
    }

    .hundred-percent {
        border: 1px solid @dx-gray-lighter;
        left: 0;
        background-color: white;
        width: 100%;
    }

    .sold {
        left: 0;
        background-color: @dx-blue;
    }

    .reserved {
        background-color: @dx-blue-light;
    }

    .shows-legend-wrapper {
        text-align: center;

        i.sold-icon,
        i.reserved-icon,
        i.free-icon {
            color: #ccc;
            margin-right: 2px;
            background-color: transparent;
            font-size: 10px;

            &:hover {
                + p {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
        i.sold-icon {
            color: @dx-blue;
        }
        i.reserved-icon {
            color: @dx-blue-light;
        }

        p.shows-sold-legend,
        p.shows-reserved-legend,
        p.shows-free-legend {
            display: inline-block;
            font-size: 10px;
            margin: 12px 5px 0 0;
            color: @dx-gray-dark;
            &:before {
                background-color: white;
                padding: 1px 6px;
                border: 1px solid #ccc;
                border-radius: 2px;
                color: @dx-text;
                content: attr(data-content);
                font-size: 14px;
                position: absolute;
                top: -20px;
                left: 5px;
                @media @dx-mobile-lg {
                    left: 0px;
                    font-size: 12px;
                    padding: 1px 3px;
                }
                @media @dx-mobile-md {
                    left: -5px;
                    font-size: 11px;
                    padding: 1px 2px;
                }
                white-space: nowrap;
                opacity: 0;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

@media @dx-mobile-sm {
    // Shrink down padding and size of dots for calendar in sidebar on small screens
    .culture-mini-calendar table td,
    .culture-mini-calendar table th {
        width: 100px;
        height: 25px;
        font-size: 12px;
    }

    .culture-mini-calendar table .date .dots .dot {
        height: 7px;
        width: 7px;
    }
}

.order-attachments-uploader {
    border: 3px dotted @dx-gray-lighter;
    border-radius: 4px;
    text-align: center;
    box-shadow: none;

    h4 {
        color: @dx-gray-darker;
        margin-top: 25px;
        margin-bottom: 20px;
    }

    &.active {
        border-color: @dx-secondary;
    }
}

.culture-sidebar {
    &__filter-mobile {
        .fa-chevron-down {
            opacity: 0.75;
        }
        .fa-chevron-right {
            opacity: 0.75;
        }
        padding-bottom: 20px;
        margin-top: -23px;
        .filter-arrangments-mobile {
            margin-top: 2px;
            .paper-container {
                > div {
                    padding-bottom: 20px;
                }
                div:only-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    &__filter {
        .fa-chevron-down {
            opacity: 0.75;
        }
        .fa-chevron-right {
            opacity: 0.75;
        }
        padding-left: 10px;
        padding-right: 10px;
        .filter-arrangments {
            .paper-container {
                > div {
                    padding-bottom: 20px;
                }
                div:only-child {
                    padding-bottom: 0;
                }
            }
        }
        .filter-arrangments-mobile {
            margin-top: -23px;
        }
    }
}
