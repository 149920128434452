.tab-component {
    float: left;
    font-size: 14;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    margin-right: 10px;
    padding: 0 10px 0 10px;

    h4 {
        font-size: 16px;
    }

    @media @dx-mobile-sm {
        padding: 0 5px 0 5px;
        margin-right: 0;

        h4 {
            // For culture -> menu items
            font-size: 16px;
        }
    }

    &:hover {
        background-color: #eee;
    }

    &.active {
        border-bottom-color: #1697eb;
    }

    &.disabled {
        border: none;
        background-color: transparent;
        color: @dx-gray;
        cursor: not-allowed;
    }

    h4 {
        margin-bottom: 3px;
    }
}

.tab-component-vertical {
    .tab-component;
    float: none;
    border-bottom: none;
    border-right: 4px solid transparent;

    &:hover {
    }

    &.active {
        border-right-color: #1697eb;
    }

    &.disabled {
        border: none;
        background-color: transparent;
        color: @dx-gray;
        cursor: not-allowed;
    }
}

a .tab-component {
    color: @dx-black;
}
