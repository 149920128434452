@sidebar-gray: #f6f7f8;

.layout-with-sidebar {
    // Fill window (below header)
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    @media print {
        bottom: auto;
    }

    .layout-with-sidebar-header {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;

        z-index: 2;

        h1 {
            flex: 0 1 auto;
            overflow: hidden; // Prioritize space for buttons
            margin: 0;
            font-size: 30px;
            font-weight: 400;
            @media @dx-mobile-sm {
                font-size: 24px;
            }
            @media @dx-mobile-xs {
                font-size: 18px;
            }
        }
    }
    .layout-with-sidebar-body {
        flex: 1 1 auto;
        flex-direction: row;
        display: flex;
        background-color: @dx-background;
        // Needed for Firefox: if not, the active header and sidebar won't be
        // sticky The fix is to add "min-height:0" on the flex-item parents of
        // the "overflow-y" element in question (the
        // .layout-with-sidebar-content in this case)
        min-height: 0;

        // .auto-elastic-scroll();

        @media @dx-mobile-sm {
            flex-direction: column;
        }

        .layout-with-sidebar-sidebar {
            flex: 0 0 200px;
            .auto-elastic-scroll();
            background-color: @sidebar-gray;
            transition: flex 400ms;

            padding: 20px;

            @media @dx-mobile-sm {
                padding: 10px;
            }

            // Overriding a few styles from Tab.less
            .tab-component {
                width: 100%;
                border-radius: 4px;
            }

            .tab-component.active {
                background-color: #1697eb;
                color: white;
            }

            // Don't display the mobile collapsable menu on larger screens
            .menu {
                display: none;
                justify-content: center;
                color: #8b8b8b;
                margin-bottom: 15px;

                p {
                    font-weight: 600;
                    margin: 0;
                }

                i {
                    font-size: 12px;
                    color: #8b8b8b;
                    padding: 2px 15px 0 15px;
                }
            }

            @media print {
                display: none;
            }

            @media @dx-mobile-sm {
                // If none of the custom styles are set, we default to 300
                flex: 0 0 300px;

                &.mobile-menu-height-50 {
                    flex: 0 0 50px;
                }

                &.mobile-menu-height-200 {
                    flex: 0 0 200px;
                }

                &.mobile-menu-height-250 {
                    flex: 0 0 250px;
                }

                &.mobile-menu-height-300 {
                    flex: 0 0 300px;
                }

                &.mobile-menu-height-350 {
                    flex: 0 0 350px;
                }

                &.mobile-menu-height-400 {
                    flex: 0 0 400px;
                }

                &.mobile-menu-height-500 {
                    flex: 0 0 500px;
                }

                &.mobile-menu-height-600 {
                    flex: 0 0 600px;
                }

                .menu {
                    display: flex;
                }
            }

            &.layout-with-sidebar-collapsed {
                transition: flex 400ms;
                // Only collapse on small screens
                @media @dx-mobile-sm {
                    flex: 0 0 45px;
                    overflow: hidden;
                }
            }

            h3 {
                color: @dx-gray-darker;
                font-size: 1.4rem;
            }
        }

        // Have to wrap the sidebar into a flex container if we are on mobile,
        // so the collapsing mechanism above works.
        .layout-with-sidebar-sidebar-wrapper {
            flex: 0 0 200px;
            overflow: auto;

            @media @dx-mobile-sm {
                flex: none;
                display: flex;
                flex-direction: column;
            }
        }

        .layout-with-sidebar-content {
            flex: 1 1 auto;
            .auto-elastic-scroll();
            padding: 20px;
            overflow-x: hidden;

            @media @dx-mobile-sm {
                padding: 0;
            }

            @media @dx-mobile-sm {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
