.dx-widget {
    @lightBackground: #fbfcfc;
    @lightBorder: #f0f1f5;
    @darkerBorder: #e0e1e5;

    margin: 15px;
    border: 1px solid @lightBorder;
    border-bottom: 2px solid @darkerBorder;

    .widget-header {
        background-color: @lightBackground;
        border-bottom: 1px solid @lightBorder;

        line-height: 40px;
        padding: 15px;
        padding-bottom: 8px;

        h2,
        .widget-subtitle {
            margin: 0;
            padding: 0;
        }

        h2 {
            backgorund: transparent;
            float: left;
            font-weight: 100;
            display: inline;
        }

        .widget-subtitle {
            float: left;
            margin-left: 15px;
            font-style: italic;
        }

        .dropdown {
            top: -4px;

            @media print {
                display: none;
            }
        }
    }

    .dropdown-toggle {
        // Hide this unless we are not hovering the widget
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
    }

    // When hovering the widget or when the menu is open: show the
    // menu button:
    &:hover .dropdown-toggle,
    .dropdown.open .dropdown-toggle {
        opacity: 1;
    }

    .widget-content {
        padding: 15px;
    }

    .details-button {
        font-weight: bold;
        text-align: center;
    }

    .widget-helper-text {
        text-align: center;
    }
}

// Sales_SummaryWidget
.dx-facts-view {
    // .fee-details {
    // 	font-size: 0.5em;
    // 	line-height: 1em;
    // 	vertical-align: middle;
    // }

    .details-popover-trigger {
        display: inline;
        border-bottom: 2px dashed @dx-gray;
        cursor: help;
    }
}
.popover-details table {
    th {
        font-weight: normal;
        padding-right: 5px;
    }

    td {
        text-align: right;
    }
}
