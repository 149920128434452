.paper-container {
    .container-fluid;
    .paper-shadow-bottom-z-1;
    background-color: white;
    border-radius: 2px; //@dx-border-radius;
    max-width: 1170px;

    &.padded {
        .container-padding;
    }

    &.slim {
        max-width: 970px;
    }
    &.fat {
        max-width: none;
    }

    // Use this for headings that only want the container behaviour (cross
    // between .container and .container-fluid), but not the style
    &.no-style {
        background-color: transparent;
        box-shadow: none;
    }

    &.yellow {
        background-color: @dx-draft;
    }
}

.paper-title {
    color: @dx-black;
    display: flex;
    line-height: normal;
    padding: 16px 16px;
    &__border {
        border-bottom: 1px solid @dx-gray-lighter;
        padding-bottom: 6px;
    }
    &__text {
        font-size: 18px;
        font-weight: 600;
    }
}

// For when we want the style, but not the container behaviour
.paper {
    .paper-shadow-bottom-z-1;
    background-color: white;
    border-radius: 2px; //@dx-border-radius;

    &.padded {
        padding: @dx-paper-container-padding;

        @media @dx-mobile-sm {
            padding: 15px;
        }

        @media @dx-mobile-xs {
            padding: 10px;
        }
    }
}
