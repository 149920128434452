.dx-facts-view table,
table.dx-facts-view {
    width: 100%;
    table-layout: fixed;

    td,
    th {
        padding: 15px;
    }

    th {
        padding-bottom: 0;
        font-weight: 100;
        font-size: 18px;
    }

    td {
        padding-top: 0;
        font-size: 30px;

        // this is a hack: since table-layout is fixed, the browser
        // tries very hard to follow this width-value. The result is
        // that columns will have equal width.
        //
        // http://stackoverflow.com/questions/10525744/css-table-cell-equal-width
        width: 2%;
    }

    .details-link {
        font-size: 80%;
        padding-left: 5px;
    }
}
