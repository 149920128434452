/*
 *  Default blue outlined checkbox
 */

.dx-checkbox-container {
    display: inline-block;

    .checkbox-wrapper {
        width: 22px;
        position: relative;
        display: inline-block;
        height: 10px;
        margin-right: 2px;

        input[type='checkbox'] {
            display: none !important;
        }
        label {
            cursor: pointer;
        }
        input[type='checkbox'] + *:before {
            border: 2px solid @dx-secondary;
            .dx-border-radius;
            content: '\00a0';
            display: inline-block;
            font: 13px sans-serif;
            height: 17px;
            width: 17px;
        }
        input[type='checkbox']:checked + *:before {
            .dx-font-awesome-pseudo;
            content: '\f00c';
            color: white;
            background-color: @dx-secondary;
        }
        input[type='checkbox']:checked + *:after {
            font-weight: bold;
        }
        input[type='checkbox']:checked + * {
            .user-select(none);
        }
    }

    label.dx-checkbox-text-label {
        .dx-label;
        display: inline-block !important;
        cursor: pointer;
        font-size: 14px;
        color: @dx-text;
        @media @dx-mobile-xs {
            font-size: 12px;
        }
    }
}

/*
 *  Default checkbox but for darker backgrounds (With white outline and dark gray checkmark)
 */
.dx-checkbox-container-dark {
    .dx-checkbox-container;

    .checkbox-wrapper {
        input[type='checkbox'] + *:before {
            border: 2px solid white;
            background-color: white;
        }
        input[type='checkbox']:checked + *:before {
            .dx-font-awesome-pseudo;
            border: 2px solid white;
            background-color: white;
            color: @dx-gray-darkest;
        }
    }

    label.dx-checkbox-text-label {
        color: white;
    }
}

/*
 *  ---------------------------- Flip and switch style checkboxes -------------------------------------
 *
 *      Based on examples found @ http://callmenick.com/post/css-toggle-switch-examples
 */

.dx-checkbox {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.dx-checkbox + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
}

/*
 *      Flip
 */
input.dx-checkbox-flip + label {
    margin: 0;
}

input.dx-checkbox-flip + label:before,
input.dx-checkbox-flip + label:after {
    .dx-border-radius;
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

input.dx-checkbox-flip + label:before {
    background-color: @dx-gray-dark;
    content: attr(data-off);
    transition: transform 0.5s;
    backface-visibility: hidden;
}

input.dx-checkbox-flip + label:after {
    background-color: @dx-primary;
    content: attr(data-on);
    transition: transform 0.5s;
    transform: rotateY(180deg);
    backface-visibility: hidden;
}

input.dx-checkbox-flip:checked + label:before {
    transform: rotateY(180deg);
}

input.dx-checkbox-flip:checked + label:after {
    transform: rotateY(0);
}

/*
 *      Switch
 */
input.dx-checkbox-switch + label {
    width: 50px;
    height: 25px;
    border-radius: 60px;
    margin: 0;
}

input.dx-checkbox-switch + label:before,
input.dx-checkbox-switch + label:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
}

input.dx-checkbox-switch + label:before {
    right: 1px;
    background-color: @dx-gray-dark; // #f1f1f1
    border-radius: 60px;
    transition: background 0.4s;
}

input.dx-checkbox-switch + label:after {
    width: 25px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: margin 0.4s;
}

input.dx-checkbox-switch:checked + label:before {
    background-color: @dx-primary;
}

input.dx-checkbox-switch:disabled + label:before {
    // background-color: @dx-gray-lighter;
    cursor: not-allowed !important;
    background-color: #b4ccaa;
}

input.dx-checkbox-switch:checked + label:after {
    margin-left: 25px;
}
