.Sticky {
    //padding: 20px;
    border-radius: 3px;
    font-size: 18px;
    color: #fff;
    //margin-bottom: 20px;
    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        margin: 0;
    }
}
