@sidebar-width: 260px;

.film-scheduler {
    //used in both table and timeline, its the wrapper around mini-calendar popup
    .calendar {
        position: fixed;
        right: 5px;
        background-color: white;
        z-index: 1000;
        padding: 10px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
        top: @dx-header-height + @main-pane-header-height;
        @media @dx-mobile {
            top: @dx-header-height-mobile + @main-pane-header-height;
        }
    }
    box-sizing: border-box;

    @border-color: @dx-gray;

    display: flex;
    flex-direction: row;
    height: ~'calc(100vh - @{dx-header-height})';
    @media @dx-mobile {
        height: ~'calc(100vh - @{dx-header-height-mobile})';
    }
    .film-scheduler-sidebar {
        flex: 0 0 @sidebar-width;
        // Hide scrollbars in Chrome at least
        &::-webkit-scrollbar {
            display: none;
        }

        &:hover {
            &::-webkit-scrollbar {
                display: block;
                -webkit-appearance: none;
                width: 10px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
        }
        overflow-y: auto;
        overflow-x: hidden;

        // Force this into an own layer so it doesn't repaint on scroll:
        // http://stackoverflow.com/questions/24741502/can-i-do-anything-about-repaints-on-scroll-warning-in-chrome-for-overflowscr
        transform: translateZ(0);

        background-color: #2f3c47;
        border-right: 1px solid @border-color;

        // To prevent zoom buttons to overlap sidebar on mobile/small screens

        .mini-calendar-container {
            border-bottom: 1px solid @border-color;
            flex: 0 0 auto;

            .calendar-mini-view {
                margin: 20px auto 20px auto !important;
            }
        }

        .search-box {
            flex: 0 0 auto;
            position: relative;

            input {
                background-color: #3d4c58;
                font-size: 16px;
                height: 50px;
                border: none;
                border-bottom: 1px solid #5b6772;
                box-sizing: border-box;
                color: white;
                padding: 15px 10px 15px 10px;

                &:focus {
                    outline: none;
                }
            }

            .keyboard-shortcut {
                position: absolute;
                right: 20px;
                top: 16px;
                color: #999999;
                pointer-events: none;
            }
        }
    }

    .film-scheduler-main-pane {
        flex-grow: 1;
        width: ~'calc(100% - @{sidebar-width})';

        .film-scheduler-header {
            background-color: white;
            height: 50px;
            overflow-y: hidden;
            overflow-x: auto;
            flex-direction: row-reverse;
            display: flex;
        }
    }

    .film-list {
        .list-unstyled;
        transform: translateZ(0);
        margin-bottom: 0;
        padding-bottom: 100px;

        li {
            border-bottom: 1px solid #414d57;

            &.header {
                background-color: #57697a;
                color: white;

                h3 {
                    font-size: 1em;
                    vertical-align: middle;
                    padding: 10px;
                    margin: 0;
                }
            }
        }
    }
}

.film-scheduler-canvas {
    height: 100%;

    h1 {
        padding-left: 20px;
        font-size: 22px;
        display: inline-block;
    }

    p {
        display: inline-block;
    }

    .toolbar {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .timeline {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background-color: white;
        // minus height of toolbar
        height: ~'calc(100% - 50px)';
        background-color: #ebeef0;
        overflow: hidden;
        position: relative;

        * {
            user-select: none;
            cursor: default;
        }

        .viewport {
            overflow: hidden;
            height: 100%;

            .canvas-margin-for-grid {
                background-color: white;
            }

            .canvas {
                position: relative;

                .timeline-grid {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;

                    .timeline-headers {
                        display: flex;
                    }

                    .grid {
                        flex: 1;
                        display: flex;
                    }
                }
                .timeline-grid-wrapper {
                    position: absolute;
                    height: 2000px;
                }
                .bg-today {
                    position: absolute;
                    height: 2000px;
                    z-index: -1;
                    background-color: #f0f2f5;
                }
            }
        }

        .resources {
            position: absolute;
            z-index: 100;
            .resource-label {
                position: relative;
                p {
                    position: absolute;
                    bottom: 0;
                    margin: 0 0 0 5px;
                    font-size: 12px;
                    color: @dx-gray-dark;
                }
            }
        }
    }

    .film-event,
    .non-film-event {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        position: absolute;
        border-radius: 8px;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        overflow: hidden;
        border: 1px solid #b4bfc6;

        &.selected {
            box-shadow: 0 0 0 1px @dx-secondary !important;
            border: 1px solid @dx-secondary;
        }

        &.draft {
            // box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5); // Old more subtle shadow
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
            border: 1px dashed #e9c3a1;
            background-color: #edebc1;
        }

        &.draft-without-shadow {
            border: 1px dashed #e9c3a1;
            background-color: #edebc1;
        }

        &.film-event-make-top {
            z-index: 100;
        }
        // Custom "selected-shadow" with blur for drafts
        &.selected-draft {
            box-shadow: 0px 4px 15px @dx-secondary !important; // subtler

            //border: 1px solid #E9C3A1;
            background-color: #edebc1;
        }

        // Trailer and commercials duration
        .pre-event {
            background-color: #ddf1ff;
            border-right: 1px solid #b4bfc6;
        }

        .poster {
            flex: 0 0 auto;
            width: 50px;
            background-position: center;
            background-size: 115%; // Before: 'cover' - but that makes it a bit too short with white gaps
            background-repeat: no-repeat;
        }

        .flags-container {
            position: absolute;
            bottom: 2px;
            left: 2px;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex-flow: flex-end;
            align-items: center;

            .flag {
                width: 20px;
                height: 20px;
                margin-right: 3px;
                line-height: 20px;
                border-radius: 20px;
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
                background: white;
                border: 1px solid #b4bfc6;
                text-align: center;
                vertical-align: middle;
                color: #e49d3f;

                i {
                    font-size: 15px;
                }

                &.flag-final-day {
                    color: #c63a43;
                }

                &.flag-note {
                    i {
                        font-size: 13px;
                    }
                }

                &.flag-babycinema {
                    background-image: url('/img/icons/baby-icon.png');
                    background-size: 11px;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }

        .film-event-content {
            padding: 5px 5px 5px 7px;
            flex: 1 1;
            position: relative;
            display: flex;
            flex-direction: column;

            * {
                pointer-events: none;
            }

            .title {
                position: absolute;
                font-weight: bold;
            }

            .sensorship {
                position: absolute;
                top: 24px;
                left: 100px;
                color: @dx-gray-darker;
            }

            .version {
                position: absolute;
                top: 24px;
                font-size: 13px;
            }

            .schedule {
                position: absolute;
                top: 46px;
                color: @dx-gray-darker;
            }

            .duration {
                position: absolute;
                top: 46px;
                left: 100px;
                color: @dx-gray-darker;
            }
        }

        .film-event-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.8);

            i {
                font-size: 18px;
                margin-top: 20px;
            }
        }

        // Selectbox:
        input {
            position: absolute;
            right: 5px;
            top: 5px;
            opacity: 1;

            &.faded {
                opacity: 0.1;
            }
        }

        .film-event-hover-trigger {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .needs-duration-update {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;

            svg {
                width: 100%;
                height: 100%;

                polygon {
                    fill: @dx-danger;
                }

                text {
                    font-family: proxima-nova, 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    fill: white;
                    font-weight: 900;
                    font-size: 40px;
                }
            }
        }
    }

    // Culture events and other non-film events
    .non-film-event {
        background-color: #f2f5f7;

        .film-event-content .title {
            font-weight: 600;
        }
    }
}

.film-event-popover {
    max-width: 500px !important;
    .popover-content {
        padding: 0 !important;
    }
}

.settings-panel-container {
    flex: 1 0 400px;
    display: flex;
    flex-direction: column;
}

.film-scheduler-settings-panel {
    flex: 1;
    padding: 20px;

    &:not(.empty) {
        background-color: white;
        border-top: 1px solid @dx-gray;
    }

    h2 {
        font-size: 16px;
        font-weight: bold;
    }
}

// Needs to be set outside of the other styles as it is the content of a popover
p.booking-context-menu-choice {
    cursor: pointer;
    margin: 7px 0px 3px;

    &:hover {
        color: @dx-secondary;
    }
}

.film-list-item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: @sidebar-width;
    height: 80px;
    position: relative;

    // pointer-events: none;
    // .user-select-none;

    // p, h3 {
    // 	pointer-events: none;
    // 	.user-select-none;
    // }

    &.booking {
        cursor: grab;

        &:hover {
            background-color: #586a79;
        }

        p.booking-period-version-title {
            font-size: 11px;
            color: @dx-gray;
            margin-bottom: 2px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        p.booking-period-info {
            font-size: 10px;
            color: @dx-gray;
            margin-bottom: 2px;
            font-weight: 100;
        }

        &.invalid {
            cursor: pointer;
            h3 {
                color: @dx-danger;
            }
            i {
                color: @dx-danger;
                font-size: 18px;
                padding: 0px 15px;
            }

            &:hover {
                background-color: inherit;
            }
        }

        .booking-context-menu-wrapper {
            position: absolute;
            right: 0;
            top: 30px;
            cursor: pointer;
        }

        p.booking-context-menu {
            padding: 0px 6px 8px;
            margin: 0 12px;
            color: white;
            font-size: 20px;
            cursor: pointer;
            border-radius: @dx-border-radius;
            background-color: #3e4c57;
            line-height: 0.4;
        }
    }

    &.selected {
        background-color: @dx-secondary;
    }

    .film-list-item-badge {
        position: absolute;
        left: 67px;
        bottom: 10px;
        font-size: 11px;
        color: @dx-gray;
    }

    img {
        flex: 0 0 55px;
        width: 55px;
        height: 80px;
    }
    h3 {
        flex: 1;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: white;
        margin-bottom: 5px;
        margin-top: 10px;
        i {
            &.fa.fa-star {
                color: @dx-warning;
                font-size: 18px;
                padding: 0px 5px 0 0;
            }
        }
    }
    .btn {
        margin: 10px;
        padding: 3px 8px;
    }
}

.scheduled-film {
    border: 1px solid @dx-gray;
    position: relative;

    height: 30px;

    .bg,
    .fg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .bg {
        background-size: cover;
        background-position: center;
        background-repeat: none;
    }

    .fg {
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
            height: 30px;
            vertical-align: middle;
            margin: 0;
            flex: 1;
            font-size: 12px;
            color: white;
            text-shadow: 2px 2px 3px black;
        }
    }
}

.film-scheduler-context-menu {
    position: absolute;
    left: @sidebar-width;
    bottom: 0;
    width: calc(~'100% - ' @sidebar-width);
    height: 40px;
    background-color: white;
    .paper-shadow-top-z-3;
    border-top: 1px solid #d9d9d9;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;

    .menu-items {
        display: flex;
        padding: 0 15px;

        .menu-choices {
            display: flex;
            div {
                padding: 0 10px;
                display: flex;
            }
            i {
                font-size: 16px;
                margin-right: 5px;
            }
        }

        p {
            margin: 0;
            margin-right: 10px;
            white-space: nowrap;
        }
    }
}

// Deprecated:
// Settings panel -------------------------------------------------
.film-scheduler-event-settings {
    position: absolute;
    left: @sidebar-width;
    bottom: 0;
    width: calc(~'100% - ' @sidebar-width);
    height: auto;
    min-height: 31px;
    background-color: white;
    .paper-shadow-top-z-3;
    border-top: 1px solid #d9d9d9;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    h3 {
        font-size: 16px;
        font-weight: 600;
    }

    p.settings-end-time {
        color: @dx-gray;
        font-size: 12px;
        // Negative margin to position closer to above input field which has padding-bottom
        margin-top: -15px;
    }

    .settings-panel-header {
        position: absolute;
        width: 100%;
        padding: 5px 10px;
        border-bottom: 1px solid #f7f7f7;
        background-color: #f3f4f8;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;

        p.settings-panel-menu {
            margin: 0;
            font-size: 14px;
            &.publish {
                margin-right: 10px;
                color: @dx-secondary;
            }
            &.publish-disabled {
                margin-right: 10px;
                color: @dx-gray-darker;
                cursor: not-allowed;
            }
            &.delete {
                color: @dx-danger;
            }
        }

        p.settings-panel-header-title {
            margin: 0;
            font-size: 14px;
            color: @dx-text;
            margin-right: 15px; //auto
        }

        // The x and minimize buttons
        .settings-panel-header-menu {
            margin-left: auto;
            color: @dx-text;

            i {
                cursor: pointer;
            }

            &:hover {
                color: @dx-secondary;
            }
        }
    }
}
//---------------------------------------------------------------------

// Booking timeline svg
g.event {
    rect {
        stroke-width: 1px;
    }

    &.sensorship_green rect {
        stroke: #6d9d3b;
        fill: #81c142;
    }

    &.sensorship_blue rect {
        stroke: #2e94b6;
        fill: #39b9e5;
    }

    &.sensorship_orange rect {
        stroke: #b88724;
        fill: #e5ab3a;
    }

    &.faded {
        opacity: 0.2;
        transition: all 0.2s;
    }
}

// SearchBookings
.search-bookings {
    float: left;
    position: relative;
    margin-right: 10px;
    border-bottom: 1px solid black;

    i.fa-search {
        color: @dx-secondary;
        margin-right: 10px;
        width: 15px;
    }

    input {
        font-size: 18px;
        padding-top: 5px;
        padding-bottom: 2px;
        width: 200px;
        border: none;
        outline: none;
    }

    .result-list {
        position: absolute;
        width: 400px;
        left: -175px;
        top: 37px;
        box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);
        background-color: white;
        border: 1px solid @dx-gray-lighter;

        h4,
        .empty-result {
            // "Tidligere bookinger" header inside result list
            padding: 5px 10px 2px 10px;
            border-bottom: 1px solid @dx-gray;
            font-size: 12px;
            font-weight: bold;
            background-color: @dx-gray-lightest;
            margin: 0;
            vertical-align: middle;

            &.empty-result {
                border: none;
                padding: 20px;
            }
        }

        ul {
            .list-unstyled;
            margin: 0;
        }

        .result-item {
            border-bottom: 1px solid @dx-gray-lightest;

            a {
                text-decoration: none;
                color: inherit;
                display: block;
                width: 100%;
                height: 100%;
                padding: 15px;

                &:hover {
                    background-color: @dx-blue-light;
                }

                h3 {
                    font-size: 14px;
                    font-weight: bold;
                    margin: 0;

                    .match {
                        color: @dx-secondary;
                    }
                }
            }
        }

        .history {
            h3 {
                color: @dx-gray-dark;

                .match {
                    color: @dx-blue-light;
                }
            }
        }
    }
}

.film-scheduler-booking-popup {
    margin: 20px;

    table.price-categories {
        td,
        th {
            padding: 3px 3px;
        }
        td:nth-child(1) {
            width: 60%;
        }
        td:nth-child(2) {
            width: 35%;
        }
        td:nth-child(3) {
            width: 5%;
        }

        .components-input {
            padding-bottom: 0;
        }
    }

    h3.booking-popup-section-header {
        color: @dx-gray-darker;
        font-size: 16px;
        margin-bottom: 25px;
    }
}

.film-scheduler-settings-shortcuts-preview {
    h3 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-weight: bolder;
    }
    a {
        display: inline-block;
        margin-top: 9px;
    }
    padding-bottom: 9px;
}
