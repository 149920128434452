.price-adjustment {
    &__templates {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    &__template {
        flex: 0 1 30%;
        margin: 10px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }
    &__template-name {
        border-bottom: 1px solid #ccc;
        padding: 10px;
        p {
            margin: 0;
            font-weight: 700;
        }
    }
    &__map {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
}
