// The mini-view displayed in the filter sidebar to support a bigger
// calendar in the main content area.
.calendar-mini-view {
    width: 200px;
    .user-select(none);

    //
    // Change colors here!
    //
    @hover-bg: @dx-gray-dark;
    @selected-bg: @dx-gray;

    .header {
        .clearfix();
        border-bottom: 1px solid @dx-gray-darkest;
        @size: 30px;
        font-size: (@size / 1.7);

        .prev,
        .next {
            &:after {
                .fa-icon();
                padding: 7px;
            }

            &:hover {
                background-color: @hover-bg;
            }

            float: left;
            font-size: (@size / 2) * 0.8;
            line-height: @size;
            vertical-align: middle;
            cursor: pointer;
        }
        .prev:after {
            content: @fa-var-chevron-left;
        }
        .next:after {
            content: @fa-var-chevron-right;
        }

        .title {
            float: right;
            .clearfix();
            line-height: @size;
            vertical-align: middle;
            margin-right: (@size / 2.8);
        }
    }

    table {
        width: 100%;
        margin-top: 10px;

        th {
            text-align: center;
        }

        tr.selected {
            background-color: lighten(@selected-bg, 10%);
        }

        .month,
        .date {
            padding: 3px;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;

            &:hover {
                background-color: @hover-bg;
            }

            &.selected {
                background-color: @selected-bg;
                &.otherMonth {
                    color: #999;
                }
            }

            &.otherMonth {
                color: #ccc;
            }
        }
    }
}
