// adds to bootstraps .modal-body
.file-upload-indicator-modal-body {
    font-size: 20px;
    text-align: center;

    i.file-upload-indicator-spinner {
        margin-left: -20px;
    }

    p.file-upload-indicator-text {
        display: inline-block;
        margin-left: 20px;
        margin-bottom: 5px;
    }
}
