@checkBoxSize: 15px;
@checkBoxLeft: 8px;
@checkBoxTop: 5px;
@checkBoxBorderRadius: 2px;
@textLeftOfCheckBoxPadding: 8px;

ul {
    .multiCheckBox {
        position: relative;
        a {
            padding: 3px 10px 3px 30px;
            &:focus {
                outline: none;
            }
            &:before {
                font-family: FontAwesome;
                position: absolute;
                content: '\00a0 ';
                width: @checkBoxSize;
                height: @checkBoxSize;
                left: @checkBoxLeft;
                top: @checkBoxTop;
                border-radius: @checkBoxBorderRadius;
                border: 1px solid @dx-gray-darker;
                font-size: 11px;
                text-align: center;
            }
        }
        &.selected {
            a {
                &:before {
                    content: '\f00c';
                    background-color: @dx-blue;
                    color: white;
                    border: none;
                }
            }
        }
    }
}
