.upload-image {
    border-radius: @dx-border-radius;
    background-color: @dx-white;
    border: 1px solid @dx-gray;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0);
    transition: transform 200ms ease-out, box-shadow 200ms ease-out, border 800ms ease-out;
    padding: 1rem;

    //min-width: 180px;
    //min-height: 180px;
    //height: auto;
    //width: auto;
    //display: inline-flex;
    position: relative;
    display: inline;
    float: left;
    cursor: pointer;
    &.upload-image:hover {
        img {
            opacity: 0.1;
        }
        .upload-image-background {
            z-index: 15;
        }
    }
    //when dragged file is over drop-zone div
    &.upload-image-hover {
        transform: scale(1.15, 1.15);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        img {
            opacity: 0.1;
            z-index: -1;
        }
    }
    // invisible upload input
    input {
        position: absolute;
        display: none;
    }

    * {
        pointer-events: none;
    }

    img {
        max-width: 100%;
        max-height: 180px;
        object-fit: contain;
        object-position: 0 0;
        z-index: 10;
        position: relative;
    }

    .upload-image-background {
        .flex-center;
        position: absolute;
        top: 1rem;
        left: 1rem;
        bottom: 1rem;
        right: 1rem;
        overflow: hidden;
        .fa-upload:before {
            z-index: 0;
            font-size: 5rem;
            color: @dx-gray;
            display: block;
        }
    }
}
