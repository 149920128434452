h1.dx-header {
    font-size: 30px;
    font-weight: 400;
    color: @text-color;
    @media @dx-mobile-lg {
        font-size: 30px;
    }
    @media @dx-mobile-sm {
        font-size: 24px;
    }
    @media @dx-mobile-xs {
        font-size: 21px;
    }
}

.dx-dark-header {
    .cards-fill-top();
    .cards-fill-sides();
    padding-bottom: 30px;

    background: #1d2e3a; /* Old browsers */

    background: -moz-linear-gradient(45deg, #1d2e3a 0%, #344e64 31%, #466884 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left bottom,
        right top,
        color-stop(0%, #1d2e3a),
        color-stop(31%, #344e64),
        color-stop(100%, #466884)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(45deg, #1d2e3a 0%, #344e64 31%, #466884 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(45deg, #1d2e3a 0%, #344e64 31%, #466884 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(45deg, #1d2e3a 0%, #344e64 31%, #466884 100%); /* IE10+ */
    background: linear-gradient(45deg, #1d2e3a 0%, #344e64 31%, #466884 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2e3a', endColorstr='#466884',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    &,
    .dx-header {
        color: white;
    }

    font-size: 18px;
    font-weight: lighter;

    small {
        color: @dx-gray;
        font-size: 0.7em;
    }
}
