.reports {
    background-color: white;
    padding: 10px 40px 50px;

    @media @dx-mobile-sm {
        padding: 1px 15px 50px;
    }
}

.reports-flex {
    display: flex;

    .first {
        margin-right: 15px;
    }

    .half {
        width: 50%;
    }

    @media @dx-mobile-md {
        flex-direction: column;

        .half {
            width: 100%;
        }

        .first {
            margin-right: 0;
        }
    }
}

.reports-charts {
    @media @dx-mobile-sm {
        border: none;
        box-shadow: none;

        .panel-heading {
            padding: 5px 10px;
        }

        .panel-body {
            padding: 5px;
        }
    }
}

.mode-reports-iframe-container {
    position: relative;
    iframe {
        border: none;
        width: 100%;
        height: 600px;
        background-color: transparent;
        &.pending {
            display: none;
        }
    }
}

.eager-loaded-typeahead {
    position: relative;
    input {
    }
    .eager-loaded-typeahead__suggestions {
        position: absolute;
        top: 25px;
        left: 0;
        width: auto;
        max-height: 150px;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid #ccc;
        p {
            margin: 0;
            padding: 5px 15px;
            cursor: pointer;
            &:hover {
                background-color: #ddd;
            }
        }
    }
}

.eb-reports-iframe-container {
    display: none;
}
