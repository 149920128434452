@ll-collapse-transition: 0.4s ease;
//===========================List of groups container
.ll-groups-container {
    //transition: all .4s ease; REMOVED BECAUSE this div doesnt have anything dynamic
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}

.ll-location-container:first-child {
    border-top: 0;
}
.ll-group-context-menu,
.ll-header-context-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ll-header-context-menu {
    margin-right: 7px;
    margin-left: 6px;
    height: 34px;
    width: 31px;
}
.ll-group-context-menu {
    margin-left: 9px;
    height: 24px;
    width: 24px;
}
//===========================LOCATION-GROUP CONTAINER
.ll-location-group > .ll-location-group-drag-source > .ll-can-hide {
    display: none;
    @media @dx-mobile-md {
        display: flex;
    }
}
.ll-location-group:hover > .ll-location-group-drag-source > .ll-group-context-menu,
.ll-location-group:hover > .ll-location-group-drag-source > .add-location {
    display: flex;
}
.add-location:hover,
.ll-move-locations {
    background-color: @dx-blue;
    color: white;
    border-color: @dx-blue;
    margin-left: 1rem;
}
.delete-locations {
    background-color: @dx-red;
    color: white;
    border-color: @dx-red;
    margin-left: 1rem;
}
.add-location,
.delete-locations-disabled,
.delete-locations-disabled:hover,
.ll-move-locations-disabled,
.ll-move-locations-disabled:hover,
.delete-locations-disabled:focus,
.ll-move-locations-disabled:focus {
    box-shadow: none;
    border: 1px solid @dx-gray-dark;
    color: @dx-gray-dark;
    margin-left: 1rem;
    background-color: transparent;
    font-weight: normal;
}
.add-location {
    padding: 3px 6px;
    @media @dx-mobile-md {
        background-color: @dx-blue;
        color: white;
        border-color: @dx-blue;
        margin-left: 1rem;
    }
}

.ll-location-group {
    order: 1;
    display: flex;
    flex-direction: column;
    transition: margin @ll-collapse-transition;
    //border: 1px solid black;
    @media @dx-mobile-sm {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

.ll-location-group-inner {
    transition: height @ll-collapse-transition;
    overflow: hidden;
    position: relative;
    border-radius: @dx-border-radius;
    //height and margin is dynamically set in JS
    &.collapsed {
        box-shadow: none;
    }
    .paper-shadow-top-z-1;
}
.ll-location-group-drop-target {
    height: 40px;
    transition: height 0.3s ease-out;
    display: flex;
    align-items: center;
    &.is-over {
        height: 60px;
    }
}
.ll-location-group-drop-target-inner {
    height: 0;
    text-align: center;
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
}
.is-over > .ll-location-group-drop-target-inner {
    opacity: 1;
}

.ll-location-group-drag-source:hover > .fa-pencil {
    opacity: 1;
}
.ll-location-group-drag-source > .fa-pencil {
    transition: opacity 0.1s ease;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    opacity: 0;
    @media @dx-mobile-md {
        opacity: 1;
    }
    height: 100%;
}
.ll-location-group-drag-source {
    font-weight: bold;
    background-color: #f6f7f8;
    padding-left: 10px;
    height: 40px;
    opacity: 1;
    cursor: pointer;
    &.is-dragging {
        opacity: 0.1;
    }
    &.ll-is-over {
        border: 3px solid @dx-gray-dark;
    }
    &.collapsed {
        border-bottom: 1px solid @dx-gray-dark;
    }
}

//===========================LOCATION-ROW
.ll-location-container {
    border-top: 1px solid #d7d7d7;
    cursor: pointer;
    &.is-being-dragged {
        display: none;
    }
}
.ll-location-checkbox {
    overflow: hidden;
    width: 0;
    transition: width 0.2s ease-out;
    &.checked {
        width: 22px;
    }
    @media @dx-mobile-sm {
        width: 22px;
    }
}

.ll-location-container:hover
    > .ll-location-drag-source
    > .ll-location-drag-source-left-container
    > .ll-location-drag-icon {
    opacity: 1;
}

.ll-location-container:hover
    > .ll-location-drag-source
    > .ll-location-drag-source-left-container
    > .ll-location-checkbox {
    width: 22px;
}

.ll-location-drag-source {
    &.newly-updated {
        background-color: #fffaea;
    }
    &.touch-held-long-enough {
        background-color: @dx-gray-lighter;
    }
    position: absolute;
    height: 50px;
    padding-left: 6px;
    padding-right: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    //padding-top: 10px;
    width: 100%;
    transition: margin-top 0.2s ease-out;
    margin-top: 0px;
    @media @dx-mobile-sm {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    &.hovered {
        margin-top: 49px;
    }
    overflow: hidden;
}

.hovered > .ll-location-drop-target-inner {
    opacity: 1;
}
.collapsed > .ll-location-drop-target-inner {
    display: none;
}
.ll-location-drop-target-inner {
    //background-color: orange;
    opacity: 0;
    height: 10px;
    width: 100%;
    transition: opacity 0.2s ease;
    text-align: center;
}
.ll-location-drop-target {
    display: flex; /* establish flex container */
    align-items: center; /* vertically center flex items */
    position: relative;
    transition: height 0.2s ease-out;
    top: -25px;
    height: 51px; //was 40, 41 to completely wrap the list in targets, so it doesnt go null in between
    margin-top: -1px;
    pointer-events: none;
    opacity: 0.2;
    &.hovered {
        //background-color: green;
        height: 100px;
    }
    &.ready-to-drop {
        pointer-events: initial;
    }
}
.ll-location-capacity-text {
    margin-right: 7px;
    width: 50px;
}
.ll-location-capacity-icon {
    margin-right: 10px;
}
.ll-location-drag-icon {
    opacity: 0;
    display: flex;
    width: 2rem;
    padding-right: 0.5rem;
    cursor: grab;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.ll-location-drag-icon > i {
    transform: rotate(90deg);
}
.ll-location-drag-source-left-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.ll-location-drag-source-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    height: 100%;
}
.ll-greyed {
    color: @dx-gray-dark;
}

//======================= SCROLL ZONE
.ll-scroll-zone-top {
    transition: height 0.6s ease;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    position: fixed;
    width: 100%;
    //height: 50px;
    font-size: 50px;
    opacity: 0;
    background-color: yellow;
    overflow: hidden;
    z-index: 1100;
    left: 0;
    top: 0;
}
.ll-scroll-zone-bottom {
    transition: height 0.6s ease;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 50px;
    //height: 50px;
    opacity: 0;
    background-color: yellow;
    overflow: hidden;
    z-index: 1100;
    left: 0;
}

//===========================CreateNewLocationGroup
.ll-create-new-group-button {
    float: left;
}
.ll-create-new-group {
    margin-left: 10px;
    height: 20px;
}

//=====================MOBILE PREVIEW
.ll-mobile-preview-single {
    .ll-location-drag-source;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    list-style-type: none;
    display: inline-block;
    vertical-align: top;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    border: 1px solid black;
}

//=====================MULTI-SELECT PREVIEW
.ll-multi-select-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.16);
    border: 1px solid @dx-gray-dark;
    will-change: transform;
    overflow: auto;
}
.ll-multi-select-preview-item {
    //margin-bottom: -5px;
    background-color: white;
    z-index: 3;
    width: 200px !important;
    max-width: ~'calc(100vw - 100px)';
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: none;
    border-top: 1px solid @dx-gray-dark;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    overflow: hidden;
}
.ll-multi-select-preview-item:first-child {
    border: none;
}

// CREATE NEW LOCATION GRP

.ll-create-new-group {
    margin-top: 50px;
    font-weight: bold;
    background-color: #f6f7f8;
    margin-left: 10px;
    height: 40px;
    opacity: 1;
    cursor: pointer;
}

// GROUP SELECTOR CONTEXT MENU

.ll-group-selector {
    min-width: 200px;
    position: absolute;
    margin-left: 10px;
    .paper-shadow-top-z-1;
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.ll-group-selector-element:hover {
    background-color: @dropdown-link-hover-bg;
}
.ll-group-selector-element {
    transition: background-color 0.1s ease;
    background-color: white;
    //border-bottom: 1px solid #d7d7d7;
    //border-right: 1px solid #d7d7d7;
    //border-left: 1px solid #d7d7d7;
    //display: flex;
    //justify-content: center;
    //flex-direction: column;
    //align-content: center;
    //height: 30px;
    padding: 1rem 1.5rem;
    //margin: 0.25rem 0;
    white-space: nowrap;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
}
// HEADER OF LL ,
.ll-header {
    margin-left: -20px;
    position: fixed;
    background-color: @dx-background !important;
    z-index: 1;
    margin-top: -35px;
    //box-shadow: 0px 9px 10px -7px rgba(0,0,0,0.75) !important;
    padding: 2rem 10px 1.5rem !important;
    //  width: ~"calc(970px - 30px)" !important;
    @media @dx-mobile-xs {
        width: 100%;
    }
}
.ll-header:after {
    content: '';
    z-index: -2;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 27px 10px -20px rgba(0, 0, 0, 0.16);
    top: 0;
}

.ll-header:before {
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    content: '';
    background-color: #f6f7f8;
    opacity: 1;
    position: absolute;
    z-index: -1;
    top: 0;
}
//filler placeholder for when group has no locationrows
.ll-empty-group-fill {
    width: 100%;
    height: 100%;
    border-top: 1px solid #d7d7d7;
}
// Inside location row
.ll-attribute-icons {
    display: flex;
    justify-content: flex-end;
    margin: 0 2rem;
}

.ll-attribute-icon {
    margin-left: 0.5rem;
}
