.reports-key-figures {
    .panel-heading {
        background-color: white;
    }

    .panel-body {
        display: flex;
    }

    .key-figure {
        margin: 15px 40px 5px;

        &:first-child {
            margin-top: 15px;
        }

        @media @dx-mobile-sm {
            margin: 15px 10px 5px;

            h2 {
                font-size: 16px;
            }
        }

        @media @dx-mobile-xs {
            &:first-child {
                margin-top: 10px;
            }
            margin: 10px 5px 5px;
            h2 {
                font-size: 14px;
            }
        }
    }

    @media @dx-mobile-sm {
        border-left: 0;
        border-right: 0;

        .panel-heading {
            padding: 5px 10px;
        }

        .panel-body {
            padding: 5px;
        }
    }
}
