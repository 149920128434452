.intercom-container {
    padding: 20px 0 20px 15px;
    @media @dx-mobile {
        padding: 10px 15px;
    }
    .intercom-button {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.2);
        color: white;
        padding: 9px 0 11px 0;
        line-height: 100%;
        text-align: center;
        height: 40px;
        width: 40px;
        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
        i {
            font-size: 20px;
            line-height: 1;
        }
        @media @dx-mobile {
            height: 32px;
            width: 32px;
            padding: 7px 0;
            i {
                font-size: 18px;
            }
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.33);
            color: white;
        }
        .white-header {
            background-color: #acb3b7;

            &:hover {
                background-color: #818c93;
            }
        }

        .unread-count-badge {
            height: 13px;
            width: 13px;
            border-radius: 13px;
            background-color: #b0242a;
            color: white;
            font-size: 8px;
            font-weight: 100;
            position: absolute;
            bottom: -8px;
        }
    }
}
