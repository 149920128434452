//--------------------- Main Colors Scheme ---------------------------------

@dx-white: #ffffff;
@dx-blue-dark: #0d5c90;
@dx-blue: #1697eb;
@dx-blue-light: #8acbf5;

@dx-green-dark: #236005;
@dx-green: #3ca408;
@dx-green-light: #9dd183;

@dx-red-dark: #97322e;
@dx-red: #d9534f;
@dx-red-light: #ee9e9c;

@dx-yellow-dark: #744b00;
@dx-yellow: #ffa500;
@dx-yellow-light: #fed58a;
@dx-draft: #fffaea;

@dx-border-color: #e6e6e6;
@dx-background: #f6f7f8;

@dx-black: #071c2b;
@dx-black-medium: #707375;
@dx-black-light: #bfbfbf;

@dx-gray-darkest: #333333;
@dx-gray-darker: #888888;
@dx-gray-dark: #adadad;
@dx-gray: #cccccc;
@dx-gray-lighter: #e6e6e6;
@dx-gray-lightest: #f3f3f3;

@dx-sidebar-bg: #2e2f32; // Dark gray

@dx-select-hover: #ebf5ff; // same as react-select

//------------------ Aliases ------------------------

@dx-text: @dx-black;

@dx-primary-dark: @dx-green-dark;
@dx-primary: @dx-green;
@dx-primary-light: @dx-green-light;

@dx-secondary-dark: @dx-blue-dark;
@dx-secondary: @dx-blue;
@dx-secondary-light: @dx-blue-light;

@dx-table-row-odd: lighten(@dx-black-light, 23%);

@dx-warning: @dx-yellow;
@dx-warning-light: @dx-yellow-light;

@dx-danger: @dx-red;
@dx-danger-light: @dx-red-light;

// -------------------------- Sizes ------------------------------------
@dx-header-height: 80px;
@dx-header-height-mobile: 52px;
@dx-ninja-height: 83px;
@dx-border-radius: 2px;
@dx-border-radius-small: 2px;

@dx-cards-padding-left: 55px;
@dx-cards-padding-right: 55px;
@dx-paper-container-padding: 30px;

// -------------------- Animation curves (Used in Cards.less)--------------------------------

@dx-curve-default: cubic-bezier(0.55, 0, 0.1, 1);
@dx-duration-default: 0.4s;

@dx-default-curve: @dx-curve-default; // for legacy only...

//--------------------- Media queries ---------------------------------

@dx-mobile-xs: ~'only screen and (max-width: 480px), only screen and (max-device-width: 480px)';
@dx-mobile-sm: ~'only screen and (max-width: 768px), only screen and (max-device-width: 768px)';
@dx-mobile-md: ~'only screen and (max-width: 992px), only screen and (max-device-width: 992px)';
@dx-mobile-lg: ~'only screen and (max-width: 1200px), only screen and (max-device-width: 1200px)';

@dx-mobile: ~'only screen and (max-width: 1024px), only screen and (max-device-width: 1024px)';
//--------------------- z indexes ---------------------------------

@dx-z-splash-page: 10000;
@dx-z-filters-popup: 2000;
@dx-z-sidebar: 1035;
@dx-z-card: 1020;
@dx-z-appbar: 1030;
@dx-z-popover: 100000;

.container-padding {
    padding: @dx-paper-container-padding;
    @media @dx-mobile-sm {
        padding: 15px;
    }

    @media @dx-mobile-xs {
        padding: 10px;
    }
}

.paper-container {
    .container-fluid;
    .paper-shadow-bottom-z-1;
    background-color: white;
    border-radius: 2px; //@dx-border-radius;
    max-width: 1170px;

    &.padded {
        .container-padding;
    }

    &.slim {
        max-width: 970px;
    }
    &.fat {
        max-width: none;
    }

    // Use this for headings that only want the container behaviour (cross
    // between .container and .container-fluid), but not the style
    &.no-style {
        background-color: transparent;
        box-shadow: none;
    }

    &.yellow {
        background-color: @dx-draft;
    }
    .paper-container-header {
        font-size: 24px;
        padding-bottom: 15px;
        display: block;
        margin-top: -10px;
        .paper-container-sub-header {
            .dx-label;
            display: block;
            font-size: 14px;
            font-weight: normal;
        }
    }
}

.popover {
    z-index: 1000;
    .popover-content {
        padding: 9px 0;
        .context-menus {
            div {
                &:hover {
                    cursor: pointer;
                    background-color: @dx-gray-lightest;
                }
            }
            &-choice {
                padding: 5px 10px 0 10px;
            }
        }
    }
}
// For when we want the style, but not the container behaviour
.paper {
    .paper-shadow-bottom-z-1;
    background-color: white;
    border-radius: 2px; //@dx-border-radius;

    &.padded {
        padding: @dx-paper-container-padding;

        @media @dx-mobile-sm {
            padding: 15px;
        }

        @media @dx-mobile-xs {
            padding: 10px;
        }
    }
}

.flex-container-for-two {
    display: flex;
    justify-content: center;
    padding: 0;

    > div {
        width: 50%;
    }

    > div:first-child {
        margin-right: 25px;
    }

    @media @dx-mobile-xs {
        flex-direction: column;

        :first-child {
            margin-right: 0;
        }

        > div {
            width: 100%;
        }
    }
}

// Classes to use for event lists and other rows that should be styled in a
// similar fashion --------------------------------------------------------
.row-padded {
    padding: 20px;

    @media @dx-mobile-sm {
        padding: 10px 5px;
    }
}

.row-padded-5 {
    padding: 5px;
}

.row-draft {
    background-color: @dx-draft !important;
}

.row-clickable {
    cursor: pointer;
    &:hover {
        background-color: @dx-border-color;
    }
}

.row-hoverable {
    .row-clickable;
    cursor: default;
}

.row-border-bottom {
    border-bottom: 1px solid @dx-border-color;
}

.row-border-top {
    border-top: 1px solid @dx-border-color;
}
// -------------------------------------------------------------

// For centering column content inside a row -----------
.row-vertical-align-center {
    // Note that this will prevent row from inheriting font-size from parent, so
    // font-size is set to standard font-size in block under
    font-size: 0;
}

.row-vertical-align-center > [class*='col'] {
    display: inline-block;
    float: none;
    font-size: 14px; // Setting default font-size, don't remove
    vertical-align: middle;
}
//-----------------------------------------------------

.comment {
    font-style: italic;
    color: @dx-black-medium;

    &.non-italic {
        font-style: normal;
    }
}

.no-margin {
    margin: 0;
}

.dangerous-text {
    color: @dx-red;
}

.success-text {
    color: @dx-primary;
}

.info-text-blue {
    color: @dx-secondary;
}

.align-right {
    text-align: right;
}

.flex-justify-around {
    display: flex;
    justify-content: space-around;

    .flex-grow-1 {
        flex-grow: 1;
    }
}

.hidden-md {
    @media @dx-mobile-md {
        display: none;
    }
}

.strong {
    font-weight: 600;
}

.stronger {
    font-weight: 700;
}

.cards-fill-top() {
    // When dx.utils.isMobile() returns true, cards are totally deactivated and
    // not wrapped in .card-content at all.
    @media @dx-mobile-sm {
        margin-top: -12px;
    }

    .card-content & {
        margin-top: -35px;
        padding-top: 35px;
    }
}
.cards-fill-sides() {
    .card-content & {
        margin-left: 0px - @dx-cards-padding-left;
        padding-left: @dx-cards-padding-left;

        margin-right: 0px - @dx-cards-padding-right;
        padding-right: @dx-cards-padding-right;
    }
}

.hr-fill-card-sides {
    margin-left: 0px - @dx-cards-padding-left;
    margin-right: 0px - @dx-cards-padding-right;
}

// Sets `top` to the header height (even if ninja mode is enabled)
.place-below-header-with-top() {
    top: @dx-header-height;
    @media @dx-mobile {
        top: @dx-header-height-mobile;
    }
}

.place-below-header-with-padding() {
    padding-top: @dx-header-height;

    .ninja-mode & {
        padding-top: @dx-ninja-height;
    }
}

// Bootstrap hack: fix content width inside hidden
// Use case: hiding divs before highcharts have rendered, causing
// charts to be rendered with default width instead of div width,
// as the latter is not known when display: none;
.hidden {
    display: block !important;
    margin: 0;
    padding: 0;
    height: 0;
    overflow-y: hidden;
}
// bootstrap hack end

.profile-picture {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #eee;
    // float: left;
    margin: 0 18px 0 0;
    overflow: hidden;
    border: 1px solid #eee;
}

.icon-hover {
    &:hover {
        color: @dx-secondary;
    }
}

// ------------ Overriding defaults --------------------------------------------

// Removes blue fuzzy line around focused inputs in Chrome
:focus {
    outline: none;
}

// ----------- For drag to work in Safari ----
.safari-draggable {
    -khtml-user-drag: element;
}

//--------------------- Mixins ---------------------------------

// Standard border radius
.dx-border-radius {
    border-radius: @dx-border-radius;
}

// For when using font awesome icon as a :before or :after
// insert own content & custom placement as needed in class that mixes this in
.dx-font-awesome-pseudo {
    // content: "\f05a";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

// Some basic defaults for our tables
.dx-table-defaults {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    color: @text-color;
    background-color: white;

    p {
        padding: 0;
        margin: 0;
    }
    //When collection is empty, backgrid displays message
    tr.empty td {
        text-align: center;
        background-color: @dx-table-row-odd;
        font-size: 16px;
        padding: 7px;
    }
}

a {
    cursor: pointer;
    -webkit-user-select: none !important;
}

.user-select-none {
    touch-callout: none !important;
    user-select: none;
    -moz-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none !important;
    -ms-user-select: none;
    -khtml-user-select: none; /* Konqueror */
}

// ----------- Convenient flex classes instead of row and col-lg-[num] -------------

.flex-container-row {
    display: flex;
    flex-wrap: wrap;

    .flex-child {
        flex: 1 0 auto;
    }

    .flex-child-padding-right {
        padding-right: 30px;
        @media @dx-mobile-xs {
            padding-right: 0;
        }
    }
}

.flex-container-column  {
    display: flex;
    flex-direction: column;
}

.mobile-padding-left {
    @media @dx-mobile-xs {
        padding-left: 15px;
    }
}

.mobile-padding-right {
    @media @dx-mobile-xs {
        padding-right: 15px;
    }
}

// ----- When content of a base card needs to have limited width to look good ------------
.content-size-adjuster-for-big-screens {
    width: 75%;
    margin: 0 auto;
    @media @dx-mobile-lg {
        width: 85%;
    }
    @media @dx-mobile-md {
        width: 100%;
    }
    @media @dx-mobile-sm {
        width: 100%;
    }
    @media @dx-mobile-xs {
        width: 100%;
    }
}

.vertical-align {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.padding-top {
    padding-top: 15px;
}

// ---------------------- Util mixins -----------------------------------

// Enabling elastic scroll on iOS requires the element to have overflow set to
// scroll. That is often not desireable because we don't want unnecessary
// scrollbars on desktop.
//
// So use this instead:
.auto-elastic-scroll() {
    overflow: auto;

    .is-mobile & {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

a.link-unstyled {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}

.dx-invalid input {
    border-color: @dx-danger-light !important;
}

.paper-shadow-top-z-1 {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}

.paper-shadow-bottom-z-1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.paper-shadow-top-z-2 {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.paper-shadow-bottom-z-2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
}

.paper-shadow-top-z-3 {
    box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.paper-shadow-bottom-z-3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
}

.paper-shadow-top-z-4 {
    box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.paper-shadow-bottom-z-4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22);
}

.paper-shadow-top-z-5 {
    box-shadow: 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.paper-shadow-bottom-z-5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2);
}

.paper-shadow-bottom-z-2-inset {
    box-shadow: 0 12px 9px -9px rgba(0, 0, 0, 0.15);
}

.pos-r {
    position: relative;
}
.margin-left-2rem {
    margin-left: 2rem;
}

.mobile-full-width {
    @media @dx-mobile-xs {
        width: 100%;
    }
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.tox-notifications-container {
    display: none !important;
}
.tox-pop {
    display: none !important;
}
