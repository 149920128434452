@sidebar-width: 260px;
@main-pane-header-height: 50px;
@drop-indicator-height: 40px;

/*
 * Here are custom styling for the TableScheduler, used in addition to the film-scheduler.less styles
 */

.table-scheduler {
    bottom: inherit; // Override film-scheduler bottom: 0 to allow overflow of content inside main container

    // Prevent ipad selection / copy popup when longpress
    *:not(input):not(textarea):not(a) {
        touch-callout: none !important;
        user-select: none !important;
        -webkit-user-select: none !important; /* disable selection/Copy of UIWebView */
        -webkit-touch-callout: none !important; /* disable the IOS popup when long-press on a link */
        -moz-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
    }

    .table-scheduler-sidebar {
        height: 100%; // Making sidebar take up the rest of the screen's height
    }

    .table-scheduler-main-pane {
        flex-grow: 1;
        width: 10%;

        .table-scheduler-header {
            background-color: white;
            height: @main-pane-header-height;
            overflow-y: hidden;
            overflow-x: auto;
            flex-direction: row-reverse;
            display: flex;
            position: relative;

            -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);

            z-index: 1; // To make box shadow be visible on top of the rest of the content

            .toolbar {
                display: flex;
                align-items: center;
                height: 100%;
                padding-left: 5px;
                padding-right: 5px;

                h5 {
                    white-space: nowrap;
                }

                .calendar-wrapper {
                    position: relative;
                    margin-right: 15px;
                }
            }
        }
    }

    .table-scheduler-scrolling-container {
        overflow-y: scroll;
        height: ~'calc(100vh - @{dx-header-height} - @{main-pane-header-height})';
        @media @dx-mobile {
            height: ~'calc(100vh - @{dx-header-height-mobile} - @{main-pane-header-height})';
        }
    }

    .table-scheduler-rows-container {
        display: inline-flex;
        flex-direction: column;
        background-color: #f6f7f8;
    }

    .table-scheduler-row {
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid #afb6ba;

        // When copy to other days-dialogue, clearly mark which day you are copying from
        &.active-day {
            border-bottom-color: @dx-secondary;
            border-top-color: @dx-secondary;
        }
    }

    .table-scheduler-row-header {
        width: 90px;
        background-color: white;
        border-right: 1px solid #e1e5e8;
        position: relative;

        left: 0;
        position: sticky;
        z-index: 100;

        -webkit-box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.3);

        .header-content {
            display: flex;
            flex-direction: column;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .header-text {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .header-text-with-toggle {
                    // &::before {
                    // 	.dx-font-awesome-pseudo;
                    // 	content: "\f0da";
                    // 	margin-right: 10px;
                    // 	font-size: medium;
                    // }

                    &::before {
                        content: attr(data-date);
                        position: absolute;
                        margin-top: 25px;
                        margin-left: 3px;
                        font-size: 12px;
                        color: @dx-gray-darker;
                    }
                }
            }

            .header-menu {
                display: flex;
                text-align: center;
                border-top: 1px solid #ccc;

                .header-menu-item {
                    width: 50%;
                    padding: 10px 2px;
                    border-left: 1px solid #ccc;

                    &:first-child {
                        border-left: 1px solid transparent;
                    }
                }
            }
        }
    }

    .table-scheduler-row-column {
        flex: 1 1;
        padding: 10px;
        padding-bottom: 25px; // To leave more space for drag to bottom
        position: relative;
        border-right: 1px solid #e1e5e8;
        min-width: 180px;

        .location-name {
            color: @dx-gray-darker;
            padding-bottom: 10px;
        }

        .last-event-finish-time {
            border-left: 1px solid @dx-gray;
            border-bottom: 1px solid @dx-gray;
            padding: 3px 5px 5px 10px;
            margin: 3px 4px;
            font-size: 12px;
            color: @dx-text;
            font-weight: bold;
            display: inline-block;
        }

        &.illegal-move {
            background-color: lighten(@brand-danger, 30%);
        }

        .loading-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 25px;
                color: @dx-gray-darker;
            }
        }
    }

    .event {
        display: flex;
        position: relative;
        background-color: white;
        border: 1px solid @dx-gray-lighter;
        padding: 4px;
        border-radius: 6px;
        cursor: pointer;
        // To avoid touch events selecting text (title) when dragging
        // user-select: none;
        // -moz-user-select: none;
        // 		-webkit-user-select: none;
        // 		-ms-user-select: none;

        .time {
            flex: 0 0 70px;

            p {
                display: inline-block;
                margin: 0 10px 0 0;
            }

            .end-time {
                font-size: 12px;
                color: @dx-gray-darker;
            }
        }

        .swap-indicator-overlay {
            display: none;
        }

        .needs-duration-update {
            position: absolute;
            right: 15px;
            align-self: center;
            color: @dx-danger;
        }

        &.swapped {
            .swap-indicator-overlay {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(1, 1, 1, 0.5);

                p {
                    text-align: center;
                    padding: 10px;
                    color: white;
                }
            }
        }

        &.draft {
            background-color: #fff2dd;
            border: 1px dotted #f4b957;
            border-left-style: solid;
        }

        &.pushed {
            // margin-left: 15px;
            .time {
                font-weight: bold;
                color: black;

                .start-time {
                    background-color: yellow;
                    border-radius: 2px;
                    padding: 0px 3px 0 3px;
                }
            }
        }

        &.moving {
            // visibility: hidden;
            background-color: @dx-gray;
            .time {
                visibility: hidden;
            }
        }

        &.longhold {
            -webkit-box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
        }

        &.culture {
            background-color: #f2f5f7;
            border: 1px solid #b4bfc6 !important; // needs to override the border set on event by javascript...
        }
    }

    .drop-indicator {
        display: flex;
        height: @drop-indicator-height;
        border: 1px dotted @state-info-text;
        border-radius: 2px;
        margin: 5px 0;
        color: @state-info-text;
        text-transform: uppercase;
        font-size: small;
        text-align: center;
        position: relative;

        // copy and move text
        p {
            margin: 10px 0 0 0;

            @media @dx-mobile-lg {
                font-size: 10px;

                .copy-text {
                    // Avoid .time to crash with Kopiér text on small screens
                    padding-left: 15px;
                }
            }
        }

        div {
            flex: 1 1;
        }

        .time {
            position: absolute;
            left: 10px;
        }

        .copy {
            background-color: @state-info-bg;

            &.hover {
                background-color: @state-info-border;
            }
        }

        .move {
            background-color: @state-success-bg;

            &.hover {
                background-color: @state-success-border;
            }
        }

        &.invisible {
            opacity: 0;
        }
    }

    .gap {
        border-left: 1px solid #8e8e8e;
        padding-left: 10px;
        margin: 3px 4px;
        font-size: 12px;
        color: #8e8e8e;

        &.warning {
            color: @brand-danger;
        }
    }

    .no-events {
        padding: 15px;
        border-radius: 2px;
        border: 1px dotted @dx-gray;
        color: @dx-gray-dark;
    }
}

.table-scheduler-settings {
    table.price-categories {
        td,
        th {
            padding: 0 3px;
        }
        td:nth-child(1) {
            width: 40%;
        }
        td:nth-child(2) {
            width: 25%;
        }
        td:nth-child(3) {
            width: 30%;
        }
        td:nth-child(4) {
            width: 5%;
        }

        .components-input {
            padding-bottom: 0;
        }
    }
}

.event-settings-warning {
    position: relative;

    .svg-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;

        svg {
            width: 100%;
            height: 100%;

            polygon {
                fill: @dx-danger;
            }

            text {
                font-family: proxima-nova, 'Helvetica Neue', Helvetica, Arial, sans-serif;
                fill: white;
                font-weight: 900;
                font-size: 40px;
            }
        }
    }
}
