.site-not-found-container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-height: 300px;
    padding: 1rem;
    * {
        margin: 0;
    }
    h1 {
        opacity: 0.15;
        font-weight: bolder;
    }
    h3 {
        opacity: 0.6;
    }
    p {
        margin-top: 1rem;
        opacity: 0.6;
        font-style: italic;
    }
}
