.paginated-typeahead-container {
    display: flex;
    width: 100%;
    .paginated-typeahead {
        position: relative;
        flex-grow: 1;
        .paginated-typeahead-suggestions {
            position: absolute;
            top: 75%;
            left: 0;
            right: 0;
            max-height: 300px;
            padding: 0;
            word-wrap: break-word;
            color: @dx-gray-darkest;
            background-color: white;
            // border: 1px solid @dx-gray;
            border-radius: 2px;
            overflow-x: hidden;
            .auto-elastic-scroll();
            list-style: none;
            z-index: 5;
            -webkit-transition: max-height 0.2s, border 0.2s;
            transition: max-height 0.2s, border 0.2s;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            .paginated-typeahead-suggestion {
                font-size: 14px;
                padding: 5px 15px;
                &:first-child {
                    padding: 10px 15px;
                }
                cursor: pointer;
                &:hover {
                    background: @dx-select-hover;
                }
            }
            .paginated-typeahead-load-more {
                border-top: 1px solid #ccc;
                font-size: 14px;
                padding: 5px 15px;
                &:first-child {
                    padding: 10px 15px;
                }
                cursor: pointer;
                &:hover {
                    background: @dx-select-hover;
                }
            }
            .paginated-typeahead-info-entry {
                font-size: 14px;
                padding: 5px 15px;
                &:first-child {
                    padding: 10px 15px;
                }
            }
        }
    }
    .paginated-typeahead-clear-btn {
        position: relative;
        margin: 20px;
        i {
            margin: 0;
            position: absolute;
            -ms-transform: translateY(-100%);
            transform: translateY(-100%);
            color: @dx-black-medium;
            &:hover {
                color: @dx-black;
            }
        }
    }
}
