.button-layout(@color) {
    background: transparent;
    border: none;
    color: @color;
}

.product-module {
    //display: flex;
    justify-content: space-between;
    min-width: 0;
    //padding-bottom: 20px;
    > div {
        margin-bottom: 20px;
    }

    &_sales-review-new {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .paper-container {
            min-height: 10px;
        }
        .days-revenue {
            display: flex;
            justify-content: space-between;
            flex: 30%;
            padding: 10px 10px 0 10px;
            &-alignment {
                display: flex;
                justify-content: center;
                .content {
                    display: flex;
                    flex-direction: column;
                    min-width: 45px;
                    &-label {
                        font-size: 16px;
                        padding-bottom: 10px;
                    }
                    &-value {
                        font-size: 25px;
                    }
                }
                .yesterday {
                    margin-left: 60px;
                }
            }
        }
        .months-revenue {
            display: flex;
            flex-direction: column;
            flex: 40%;
            padding: 10px 10px 0 10px;
            &-month {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding-bottom: 10px;
                min-width: 220px;
                @media (max-width: 480px) {
                    border-top: 1px solid @dx-gray-lighter;
                }
                border-bottom: 1px solid @dx-gray-lighter;
                .is-month-revenue-increase {
                    color: @dx-green;
                }
                .is-month-revenue-decrease {
                    color: @dx-red;
                }
                .amount {
                    font-weight: 600;
                }
            }
            &-year {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                .is-year-revenue-increase {
                    color: @dx-green;
                }
                .is-year-revenue-decrease {
                    color: @dx-red;
                }
                .amount {
                    font-weight: 600;
                }
            }
        }
    }

    .paper-container {
        padding: 0;
        //min-height: 300px;
        &.padded {
            padding: 0;
        }
    }
    &__first-row {
        width: 380px;
    }

    &__column-chart {
        &__body {
            padding-top: 60px;
        }
    }

    &__body {
        padding: 10px;
        border-bottom: 1px solid @dx-gray-lighter;
        button {
            border: none;
        }
        &-filter {
            display: flex;
            justify-content: space-between;
            .btn-group {
                .btn-default {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        &-button {
            display: flex;
            justify-content: flex-end;
        }
        .text-header {
            font-size: 16px;
            font-weight: 600;
            line-height: 36px;
        }
    }

    &__sales-per-product-list {
        .paper-container {
            min-height: 440px;
            overflow-x: scroll;
            .accordion {
                max-height: 450px;
                &__main-body {
                    max-height: 900px;
                    overflow-y: scroll;
                    padding: 0;
                }
            }
        }
        &-content {
            min-width: 470px;
            .accordion-mobile {
                max-height: 20px;
            }
        }
    }

    &__product-group-accordion {
        .paper-container {
            &.padded {
                padding: 0;
            }
        }
    }
    &__product-group-count {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0 10px 0;
    }
    &__product-group-table {
        padding-bottom: 20px;

        .paper-container {
            padding: 0;
        }
        &-views {
            .paper-container {
                overflow-x: auto;
                min-width: 68px;
                padding: 0;
            }
        }
        .product-list-view {
            overflow-x: auto;
            .paper-container {
                padding: 0;
            }
        }
        &-report {
            min-width: 500px;
            overflow-x: auto;
            .paper-container {
                min-width: 600px;
                padding: 0;
            }
        }
        &_filter {
            display: flex;
            justify-content: space-between;
            padding: 10px 0 10px 10px;

            div:first-child {
                flex: 1 030%;
            }
            .group-view {
                display: flex;
                justify-content: space-between;
                min-width: 50px;
                background-color: ghostwhite;
                padding-top: 5px;
            }
            button {
                border: none;
            }
            .btn-group {
                .btn-default {
                    font-size: 16px;
                    font-weight: 600;
                    @media (max-width: 470px) {
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }
            border-bottom: 1px solid @dx-gray-lighter;
        }

        &_filter--down {
            display: none;
        }

        &_filter--up {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px solid @dx-gray-lighter;
            .filter-criteria-title {
                display: flex;
                font-weight: bold;
                padding: 10px 0 0 20px;
            }
            .filter-criteria {
                display: flex;
                padding: 10px 0 0 20px;
                > label {
                    font-weight: normal;
                    padding: 0 0 0 2px;
                }
                label:nth-child(2) {
                    padding-left: 20px;
                }
            }
        }

        .product-group_create-new {
            display: flex;
            justify-content: flex-end;
            margin-bottom: -45px;
            &__split-button {
                margin-left: auto;
                padding: 0 10px 10px 10px;
                .dropdown-menu {
                    z-index: 10000000;
                }
                @media (max-width: 470px) {
                    button {
                        font-size: 13px;
                        max-width: 88px;
                    }
                }
            }
        }

        &__content {
            //min-width: 470px;
            padding: 0;
            .accordion__item-row__cell {
                word-break: normal;
            }
        }

        .sub-header {
            background-color: @dx-gray-lightest;
        }
    }
    &__revenue {
        flex: 1 1 100%;
        flex-direction: row;
        max-height: 350px;
        .paper-title {
            .paper-title;
        }
        &__days {
            display: flex;
            justify-content: space-around;
            padding: 76px 26px 6px 26px;
            &-day {
                padding-top: 10px;
                .header {
                    font-weight: 300;
                }
                .body {
                    font-size: 30px;
                    font-weight: 600;
                }
            }
            &-border {
                border-bottom: 1px solid @dx-gray-lighter;
                padding-bottom: 55px;
            }
        }
        &__months-years {
            padding-left: 10px;
            padding-right: 10px;
            .months-revenue {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                .period-label {
                    padding-right: 0;
                }
                .is-month-revenue-increase {
                    color: @dx-green;
                }
                .is-month-revenue-decrease {
                    color: @dx-red;
                }
                &__border {
                    border-bottom: 1px solid @dx-gray-lighter;
                    padding: 4px 4px 4px 4px;
                }
            }
            .years-revenue {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding: 4px 0 4px 4px;
                .period-label {
                    padding-right: 35px;
                }
                .is-year-revenue-increase {
                    color: @dx-green;
                }
                .is-year-revenue-decrease {
                    color: @dx-red;
                }
            }
        }
    }
    &__sales-per-product-group-last-seven-days {
        flex-direction: row;
        flex: 1 1 100%;
        .paper-title {
            .paper-title;
        }
    }

    .new-product {
        .dates {
            display: flex;
        }
    }
    &__search {
        overflow: auto;
        .paper-container {
            padding: 0;
            margin-bottom: 20px;
        }
        &-result {
            font-weight: bold;
            padding-bottom: 10px;

            small {
                padding-left: 10px;
                opacity: 0.5;
            }
        }
    }
}

.items-search-result {
    .paper-container {
        margin-bottom: 20px;
    }
}

.active-header-content {
    .active-header-content__editor {
        display: flex;
        flex-direction: row;
        padding-right: 300px;
        .active-header-content__editor-user-avatar {
            flex: 0 1 20%;
            padding: 6px;
            color: @dx-blue;
        }
        .active-header-content__editor-info {
            flex: 1 0 100%;
            padding: 2px;
            .fa {
                font-size: 10px;
                opacity: 0.5;
            }
            &-name {
                font-size: 14px;
                font-style: italic;
            }
            &-date {
                font-style: italic;
                opacity: 0.75;
            }
        }
    }
}

.product-overview {
    .sales-per-product-overview {
        .paper-container {
            padding-left: 0;
            padding-right: 0;
            &.padded {
                padding-top: 0;
            }
        }
        button {
            .button-layout(@dx-white);
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            width: 230px;
            padding-top: 20px;
            padding-left: 20px;
        }
        &__header {
            .dx-dark-header {
                padding-left: 10px;
            }
            &-time-period {
                padding-top: 15px;
                padding-left: 90px;
                .btn-group {
                    border-bottom: 1px dotted;
                }
            }
        }
    }
}

.product-logs {
    padding-top: 30px;
    overflow-x: scroll;
    .paper-container {
        min-width: 470px;
        &.padded {
            padding: 0;
        }
    }
}

.product-sales-tree-map {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    .title {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        .time-period {
            padding-top: 10px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        button {
            .button-layout(@dx-black);
        }
        .title {
            padding-top: 17px;
            .btn-group {
                border-bottom: 1px dotted;
            }
        }
        &-border {
            border-bottom: 1px solid @dx-gray-lighter;
            padding-bottom: 0px;
            padding-right: 30px;
        }
        .paper-title__border {
            border-bottom: none;
        }
    }
    &__body {
        display: flex;
        padding: 0 0 0 0;
        .reports-charts {
            width: 100%;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;

        &-sold-units {
            display: flex;
            flex: 1 0 10px;
            justify-content: space-between;
            flex-direction: column;
            padding-top: 3px;
            padding-left: 20px;
            .result {
                font-weight: 500;
            }
        }
        &-revenue {
            display: flex;
            flex: 2;
            justify-content: space-between;
            flex-direction: column;
            padding-top: 3px;
            .result {
                font-weight: 500;
            }
        }
    }
}

.product-menu-groups-detail-layout {
    padding-right: 10px;
    padding-left: 10px;
}

.product-menus-groups {
    margin: 0 auto;
    max-width: 1170px;
}

.product-menus {
    .paper-container {
        padding: 0;
    }

    &__body {
        padding-bottom: 20px;

        &-accordion-table {
            overflow-x: auto;
            padding: 0;
            .accordion {
                min-width: 470px;
                &__header {
                    .accordion__item-row__cell {
                        word-break: normal;
                    }
                }
            }
        }
        &-add-product {
            padding: 30px 0 0 0;
            .paper-container {
                overflow-x: unset;
            }
            &__field {
                padding: 30px 5px 0 15px;
            }
        }
    }
    &__info {
        display: flex;
        padding: 10px 0 10px 10px;
        color: #3a87ad;
        background-color: #d9edf7;
        border-color: #bce8f1;
        flex-basis: 100%;
        margin-bottom: 20px;
    }
    &__create {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0 20px 10px;
        flex-wrap: wrap;
        flex-basis: 100%;

        &-menus {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.product-sales-group-table-report {
    .paper-container {
        margin-left: 0;
        max-width: 6000px;
        overflow-x: auto;
        .products-view-report {
            display: flex;
            justify-content: space-between;
            .btn-group {
                padding: 10px 10px 10px 10px;
            }
            border-bottom: 1px solid @dx-gray-lighter;
        }
    }
    .accordion {
        overflow-x: auto;
    }
    .paper-title__text {
        font-weight: normal;
    }
}
