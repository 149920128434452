.integration-tile {
    margin-top: 10px;
    height: 220px;
    flex-basis: 20%;
    @media @dx-mobile-md {
        flex-basis: 33.333% !important;
    }
    @media @dx-mobile-xs {
        flex-basis: 50% !important;
    }
    padding-left: 5px;
    padding-right: 5px;
    a {
        &:hover {
            text-decoration: none;
        }
        color: inherit;
    }
}

.integration-tile-inner {
    height: 100%;
}

.integration-tile-collection-container {
    display: flex;
    max-width: 970px;
    flex-wrap: wrap;
    margin: 0 auto;
}

.integration-tile-collection {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    flex-basis: 100%;
    flex-grow: 1;
}

.integration-status-circle-indicator {
    .fa;
    &:before {
        content: @fa-var-circle;
    }
    margin-right: 5px;
    font-size: 80%;
    &.integration-status-circle-is-issue {
        color: @dx-red;
    }
    color: @dx-green;
}
