.dx-widget-card-display {
    margin-bottom: 20px;

    .widget-header {
        background-color: transparent;

        h2,
        .widget-subtitle {
            margin: 0;
            padding: 0;
        }

        h2 {
            backgorund: transparent;
            float: left;
            font-weight: 100;
            display: inline;
        }

        .widget-subtitle {
            float: left;
            margin-left: 15px;
            font-style: italic;
        }

        .dropdown {
            top: -4px;

            @media print {
                display: none;
            }
        }
    }

    .dropdown-toggle {
        // Hide this unless we are not hovering the widget
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
    }

    // When hovering the widget or when the menu is open: show the
    // menu button:
    &:hover .dropdown-toggle,
    .dropdown.open .dropdown-toggle {
        opacity: 1;
    }

    .widget-content {
        padding: 0;
        .widget {
            p.widget-no-sales-msg {
                color: @dx-gray;
                margin-left: 15px;
            }
        }
    }

    .details-button {
        font-weight: bold;
        text-align: center;
    }

    .widget-helper-text {
        text-align: center;
    }
}
