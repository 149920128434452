.empty-grid-message,
.empty-grid-message-left {
    text-align: center;
    margin-top: 25px;
    font-size: 16px;
    @media @dx-mobile-sm {
        margin-top: 20px;
        font-size: 14px;
    }
}

.empty-grid-message-left {
    text-align: left;
}
