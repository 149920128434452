@font-path: '../fonts';

.proximanova-url(@name) {
    src: url('@{font-path}/proximanova-@{name}.eot');
    src: url('@{font-path}/proximanova-@{name}.eot?#iefix') format('embedded-opentype'),
        url('@{font-path}/proximanova-@{name}.woff2') format('woff2'),
        url('@{font-path}/proximanova-@{name}.woff') format('woff'),
        url('@{font-path}/proximanova-@{name}.ttf') format('truetype'),
        url('@{font-path}/proximanova-@{name}.svg#proxima_novablack') format('svg');
}

@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('light');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('lightitalic');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('regular');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('regitalic');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('semibold');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('semibolditalic');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('bold');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('boldit');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('extrabold');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'proxima-nova';
    .proximanova-url('black');
    font-weight: 900;
    font-style: normal;
}
