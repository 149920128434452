.menu-component-wrapper {
    display: flex;
    position: relative;
    margin-left: auto;
    // .paper-shadow-bottom-z-1;
    .menu-components-container {
        display: flex;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
        @media @dx-mobile {
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 48px;
            height: 0;
            overflow: hidden;
            box-shadow: none;
            border: none;
            &.open {
                height: auto;
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
        .menu-component {
            cursor: pointer;
            padding: 12px 15px;
            background-color: rgba(255, 255, 255, 0.2);
            color: white;
            box-shadow: none;
            border-left: 1px solid @dx-green;
            &:first-child {
                border-left: none;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                @media @dx-mobile {
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 4px;
                }
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            p {
                margin: 0;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.33);
                @media @dx-mobile {
                    text-decoration: none;
                    color: #262626;
                    background-color: #f5f5f5;
                }
            }

            &.active {
                background-color: rgba(255, 255, 255, 1);
                color: @dx-black;
                &:hover {
                    background-color: rgba(245, 245, 245, 1);
                }
                @media @dx-mobile {
                    text-decoration: none;
                    color: #262626;
                    background-color: #f5f5f5;
                }
            }

            &.disabled {
                background-color: transparent;
                color: @dx-gray;
                cursor: not-allowed;
            }
            .has-dx-user-as-partner & {
                border-left: @dx-red;
            }
            @media @dx-mobile {
                background-color: white;
                color: @dx-black;
                border: none;
            }
        }
    }
    @media @dx-mobile {
        display: block;
        height: 32px;
        padding: 0 0 0 50px;
        margin-left: 0;
        &.open {
            height: auto;
            .header-menu-dropdown-toggler {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .header-menu-dropdown-toggler {
        display: none;
        position: relative;
        color: white;
        font-size: 18px;
        padding: 8px 0;
        line-height: 1;
        height: 32px;
        text-decoration: none;
        font-weight: 600;
        @media @dx-mobile {
            padding-right: 20px;
            display: block;
        }
        &:after {
            font-family: 'FontAwesome', sans-serif;
            content: '\f107';
            position: absolute;
            right: 0;
            transition: transform 0.2s;
        }
    }
}
