.Select-control {
    border: none;
    height: 25px;

    &:hover {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
}

.is-focused:not(.is-open) > .Select-control {
    border: none;
    box-shadow: none;
}

.Select-menu-outer {
    border: 1px solid @dx-gray-lightest;
    border-top-color: @dx-gray-lightest;
    margin-top: 0;
    max-height: 250px;
    word-break: break-all;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    .Select-menu {
        max-height: 250px;

        .Select-option {
            min-height: 34px;
        }
    }
}

.Select-input {
    height: 25px;
    padding-left: 2px;
}

.Select-value,
.Select-placeholder {
    padding-left: 2px !important;
    color: @dx-text;
    font-size: 16px;
    line-height: 30px !important;
    z-index: 0;
}

.Select-placeholder {
    color: @dx-gray-dark;
    &:focus {
        visibility: hidden;
        display: none;
    }
}

.Select.is-disabled > .Select-control {
    background-color: transparent;
    cursor: not-allowed;

    .Select-value-label {
        color: @dx-gray !important;
    }
}

.Select-clear-zone {
    // vertical-align: bottom;
    padding-top: 5px;
}

@select-option-focused-bg: @dx-blue-light;
