// Main design of button (combined with bootstrap .btn
.dx-btn {
    .dx-border-radius;
    vertical-align: top;

    h3 {
        display: inline;
        font-size: 18px;
        padding: 0;
        margin: 0;
        @media @dx-mobile-sm {
            font-size: 13px;
        }
    }

    i {
        margin-right: 7px;
        min-width: 15px;
    }

    span {
        margin-top: -3px;
        margin-left: 7px !important;
    }
}

.dx-btn-huge {
    padding: 15px 25px 15px 25px;

    h3 {
        font-size: 25px;
        margin-right: 10px;
    }

    i {
        font-size: 25px;
        margin-right: 10px;
    }
}

.context-menu > .dx-btn,
.date-range-wrapper > .dx-btn,
.shrink-btn {
    @media @dx-mobile-sm {
        h3 {
            display: none;
        }

        i {
            margin-right: 0;
            min-width: 15px;
        }
    }
}
