// For now: keep this as a sub-header inside layout-with-filters.jsx, instead of
// replacing the main navbar

@warning-header-color: @dx-red;

.placeholder-color(@color) {
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: @color;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: @color;
        opacity: 1;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: @color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: @color;
    }
    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: @color;
    }
}
#main-navbar {
    border: none;
    min-height: 0;
    .paper-shadow-bottom-z-1;
    .navbar-header {
        .sidebar-mobile & {
            margin: 0;
        }
        .dropdown {
            .dropdown-toggle {
                font-size: 24px;
                font-weight: 600;
                @media @dx-mobile {
                    padding: 16px 5px;
                    height: 52px;
                    font-size: 18px;
                }
            }
        }
    }
    .intercom-container {
        position: absolute;
        top: 0;
        right: 20px;
        @media @dx-mobile-sm {
            right: 0;
        }
    }
    .has-dx-user-as-partner {
        background-color: @warning-header-color;
    }
}
.active-header {
    // position: fixed;
    // width: calc(~"100% - 50px");
    // color: white;
    // padding-left: 15px;
    // padding-right: 15px;
    // z-index: 10000; // Just to keep it displayed on top of current navbar...
    // top: 0;
    // height: 55px;
    // .paper-shadow-bottom-z-1;

    .paper-shadow-bottom-z-1;
    display: flex;
    width: 100%;
    height: @dx-header-height;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    color: white;
    background-color: @dx-green;
    position: relative;
    z-index: 10;
    .active-header-content {
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        @media @dx-mobile {
            flex: 0 1 100%;
            padding: 0;
            flex-wrap: wrap;
        }
        .date-location {
            margin: 0;
            @media @dx-mobile {
                display: none;
            }
        }
        .active-header-title-container {
            @media @dx-mobile {
                padding: 0 0 0 50px;
            }
            h3 {
                &.active-header-title {
                    // margin-top: 10px;
                    margin: 0;
                    line-height: 1;
                    padding: 16px 0;
                    font-weight: 600;
                }
            }
            p {
                padding: 6px 0 0;
                margin: 0;
                text-transform: uppercase;
                color: @dx-gray-darker;
                @media @dx-mobile {
                    font-size: 11px;
                }
                + h3.active-header-title {
                    padding: 0 0 6px 0;
                    font-weight: 600;
                    @media @dx-mobile {
                        font-size: 18px;
                    }
                }
            }
        }
        .plwf-buttons {
            flex: 0 0 auto;

            // Filter button should be hidden unless we are on a small screen
            .filterButton {
                display: none;
                // margin-right: 15px;

                @media @dx-mobile-lg {
                    display: inline;
                }
            }
        }
        // search field - move this to different file and target a different class
        .active-header-search-container {
            max-width: 300px;
            width: 100%;
            position: relative;
            margin: auto;
            @media @dx-mobile {
                flex: 0 1 0;
                position: static;
                transition: all 0.2s;
                margin-left: auto;
                margin-right: 0;
            }
            .search-icon {
                transition: all 0.2s;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                position: absolute;
                top: 11px;
                left: 16px;
                @media @dx-mobile {
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
                    background-color: rgba(255, 255, 255, 0.2);
                    display: block;
                    float: right;
                    right: 0;
                    left: auto;
                    top: auto;
                    position: relative;
                }
                &:before {
                    display: block;
                    content: '\f002';
                    font-family: FontAwesome, sans-serif;
                    @media @dx-mobile {
                        position: absolute;
                        right: 9px;
                        top: 5px;
                        left: auto;
                    }
                }
            }
            input {
                max-width: 100%;
                background-color: rgba(255, 255, 255, 0.2);
                border: none;
                box-shadow: none;
                border-radius: 4px;
                color: white;
                transition: all 0.2s;
                padding: 11px 0 11px 36px;
                height: 45px;
                .placeholder-color(white);
                &:hover {
                    background-color: rgba(255, 255, 255, 0.33);
                }
                &.focused,
                &:focus {
                    background-color: rgba(255, 255, 255, 1);
                    color: @dx-black;
                    //.placeholder-color(@dx-black);
                    + .search-icon {
                        color: @dx-black;
                    }
                }
                @media @dx-mobile {
                    width: 100%;
                    height: 100%;
                    border-radius: 14px;
                    padding: 0;
                    transition: all 0.3s cubic-bezier(0.2, 0, 0.1, 1);
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    .placeholder-color(transparent);
                    color: transparent;
                    &.focused {
                        color: @dx-black;
                        width: auto;
                        padding: 6px 12px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }
        .active-header-toolbar {
            margin-left: auto;
            display: flex;
            flex-direction: row-reverse;
            button {
                padding: 9px 15px;
                color: white;
                background-color: rgba(255, 255, 255, 0.2);
                border: 1px solid @dx-green;
                margin-left: 15px;
                border-radius: @dx-border-radius-small;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.33);
                }
                &:focus {
                    outline-color: @dx-green;
                }
                &:active {
                    box-shadow: none;
                }
                &:first-child {
                    color: @dx-blue;
                    background-color: white;
                    border-color: white;
                    &:focus {
                        outline-color: white;
                    }
                    &:hover {
                        background-color: rgba(245, 245, 255, 1);
                    }
                }
                &:last-child {
                    margin-left: 0;
                }
                @media @dx-mobile {
                    font-size: 14px;
                    padding: 5px 12px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-left: 15px;
                        // if this is the first, AND the last child, no margins.
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
                span {
                    &.active-header-button-icon {
                        font-family: FontAwesome, sans-serif;
                        float: left;
                        padding-right: 10px;
                        display: block;
                        @media @dx-mobile {
                            display: block;
                            padding: 0;
                            line-height: 1;
                            position: relative;
                            left: -5px;
                            top: 3px;
                        }
                        &.new {
                            &:after {
                                content: '\f067';
                            }
                        }
                        &.files {
                            &:after {
                                content: '\f15b';
                            }
                        }
                        &.search {
                            &:after {
                                content: '\f002';
                            }
                        }
                    }
                }
            }
            @media @dx-mobile {
                display: flex;
                flex-direction: row;
                flex: 1 0 100%;
                width: 100%;
                padding: 10px;
                background-color: #339800;
            }
        }
    }
    &.with-back-button {
        background-color: white;
        color: @dx-black;
        &.inactive {
            background-color: @dx-draft;
        }
        .back-button {
            font-size: 66px;
            line-height: 1;
            background-color: @dx-primary;
            color: white;
            height: 80px;
            text-align: center;
            flex: 0 0 70px;
            position: relative;
            .fa {
                font-weight: 700;
                position: absolute;
                top: 5px;
                left: 19px;
            }
            &:hover {
                background-color: #42b309;
            }

            @media @dx-mobile {
                padding: 0;
                font-size: 52px;
                line-height: 1;
                height: 52px;
                flex: 0 0 50px;
                .fa {
                    top: -1px;
                    left: 14px;
                }
            }
        }
        .active-header-content {
            padding-left: 15px;
            height: 100%;
            flex-wrap: nowrap;
            min-width: 0; // wtf - no idea why this works... but it does
            .active-header-title-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 20px;
                padding-left: 0;
                min-width: 0; // wtf - no idea why this works... but it does
                h3.active-header-title {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            // Buttons should be aligned to right
        }
        .intercom-button {
            background-color: rgba(6, 27, 43, 0.33);
            &:hover {
                background-color: rgba(6, 27, 43, 0.5);
            }
        }
    }
    &.has-dx-user-as-partner {
        background-color: @warning-header-color;
        &.with-back-button {
            background-color: white;
            .back-button {
                background-color: @warning-header-color;
                &:hover {
                    background-color: @warning-header-color;
                    opacity: 0.85;
                }
            }
        }
    }
    @media @dx-mobile {
        align-items: center;
        justify-content: space-around;
        height: auto;
        align-items: flex-start;
        .menu-component-wrapper {
            order: 1;
        }
        .active-header-search-container {
            order: 2;
        }
        .active-header-toolbar {
            order: 4;
        }
        .intercom-container {
            order: 3;
        }
        h3 {
            font-size: 18px;
        }
        &.search-has-focus {
            position: relative;
            .active-header-content {
                .active-header-search-container {
                    max-width: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 50px;
                    padding: 10px;
                    background-color: @dx-green;
                    z-index: 1;
                    transition: all 0.2s;
                    .search-icon {
                        display: none;
                    }
                    input {
                        position: static;
                        float: left;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
