// Main content area
.main {
    background-color: #fff;
    position: absolute;
    .auto-elastic-scroll();
    left: 200px;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: @dx-background;
    &.with-header {
        .place-below-header-with-top();
    }
}
