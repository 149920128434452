@longTransition: 700ms ease;
@shortTransition: 350ms ease;
@veryShortTransition: 100ms ease;
@gridColor: #c7d2d9;
@headerNSide: #e7eaee;
@borderWidth: 1px;
@containerPaddingRight: 20px;

.header-date {
    text-align: left;
    margin-left: @containerPaddingRight;
    margin-top: 20px;
}
.timeline-container {
    position: absolute;
    overflow: hidden;
    width: ~'calc(100% - 45px)'; //calculated by erling and hans aprox using ruler
    height: ~'calc(100% - 75px)'; // --||--
    top: 50px;
    left: @containerPaddingRight;
    background-color: #f0f2f5 !important;

    @media @dx-mobile-sm {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}
.canvas-main-outer {
    height: 100%;
    overflow: scroll;
    position: relative;
    cursor: grab;
}
.canvas-main-outer:active {
    cursor: grabbing;
}
.canvas-main-inner {
    width: 1600px;
    height: 2200px;
    position: relative;
}
.canvas-side {
    height: 2200px;
    position: relative;
    width: 100%;
}
.canvas-top {
    height: 100%;
    position: relative;
    width: 1600px;
}

// CARDSSSSS
// EVENT-CArds
.event-card-outer {
    transition: left @longTransition, top @longTransition, opacity @shortTransition;
    //transition: all 1000ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
}

.timeline-calendar-buttons {
    margin-left: 10px;
    margin-top: 10px;
}
#popover-trigger-hover-focus-month  {
    min-width: 250px;
}
.grouping-card-inner {
    cursor: pointer;
    background-color: white;
    margin: 0.25rem;
    width: 100%;
    height: 90%;
    overflow: hidden; // should have made elipsis work
    z-index: 2;
}
.event-card-inner > .sales-bar {
    max-width: 300px;
}

.event-card-inner {
    position: relative;
    transition: margin @veryShortTransition, max-height @veryShortTransition;
    max-height: 100%;
    cursor: pointer;
    //  background-color: #c6ddf8;
    background-color: white;
    margin: 0.5rem 0;
    border: 0.1px solid #fafafa;
    width: 100%;
    height: 90%;
    overflow: hidden; // should have made elipsis work
    z-index: 2;
    white-space: nowrap;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    p {
        margin: 0.2em;
    }
}
.event-card-inner:hover {
    p {
        margin: 0.1em;
    }
}
.event-card-inner-week {
    margin-left: 1%;
    margin-right: 1%;
}
.event-card-inner-related-event-highlighted {
    background-color: #edf1f4;
}
.event-card-inner-highlighted {
    background-color: #edf1f4;
    height: auto;
    margin: 0;
    white-space: normal;
    z-index: 10;
    max-height: 200%;
    min-height: 100%;
}
//behind event-card-inner (z-index 1)
.svgLines {
    position: absolute;
    top: -50%; //(175-100)/2
    left: 0;
    width: 100%;
    height: 200%;
    z-index: 1;
}

#month-header:first-letter {
    text-transform: capitalize;
}

.event-card-outer-hidden {
    opacity: 0;
}

.grouping-clear {
    position: absolute;
    background-color: #d6dbe2;
    height: 100%;
    width: 100%;
}
.grouping-occupied {
    position: absolute;
    background-color: #9bc1ee;
    height: 100%;
}

// header cards
.header-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1%;
    border-bottom: @borderWidth solid @gridColor;
    border-right: @borderWidth solid @gridColor;
    font-weight: bold;
    position: absolute;
    height: 100%;
    background-color: @headerNSide;
}
.scroll-master {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-bottom: @borderWidth solid @gridColor;
    border-right: @borderWidth solid @gridColor;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: @headerNSide;
    font-size: 20px;
}

//Side cards (flat no inner)
.side-card {
    position: absolute;
    width: 100%;
    left: 0;
    transition: top @longTransition, height @longTransition, opacity @longTransition;
    background-color: @headerNSide;
    margin: 0 auto;
    border-bottom: @borderWidth solid @gridColor;
    border-right: @borderWidth solid @gridColor;
}
.side-card-hidden {
    opacity: 0;
}

// side card inner <p>
.side-card-location {
    padding-top: 5%;
    padding-left: 15%;
}
.side-card-grouping {
    padding-top: 5%;
    padding-left: 5%;
    cursor: pointer;
}

.svg-grid {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible !important;
    fill: @gridColor;
}

//MONTHSTUFF
.day-square {
    float: left;
    width: 100/7%;
    height: 100%;
    border-left: 1px solid @gridColor;
    border-bottom: 1px solid @gridColor;
    overflow: visible;
    padding-top: 10px;
    position: relative;
}

.event-single-day {
    cursor: pointer;
    float: left;
    background-color: #37a61d;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 2px;
    @media @dx-mobile-sm {
        height: 7px;
        width: 7px;
        margin: 1px;
    }
}
.greyed .event-single-day {
    background-color: #000;
    opacity: 0.2;
}
.event-multi-day {
    cursor: pointer;
    background-color: white;
    height: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-top: 2%;
    position: relative;
    z-index: 1;
    /*@media @dx-mobile-sm {
    margin-top: 5%;
    font-size: 4px;
    height: 6%;
  }*/
}
.event-multi-day.greyed {
    opacity: 0.5;
}
.event-multi-day-spacer {
    height: 20px;
    margin-top: 2%;
    position: relative;
    z-index: -1;
    /*@media @dx-mobile-sm {
    margin-top: 5%;
    height: 6%;
  }*/
}
.event-multi-day-inner {
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 0.25%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /*@media @dx-mobile-sm {
    padding: 0;
  }*/
}
.single-day-event-row {
    width: 100%;
    padding: 3%;
    overflow: hidden;
}
.day-square-heading {
    font-weight: bold;
    padding: 4px;
}
.today-heading {
    border-radius: 50%;
    background-color: grey;
    width: 27.5px;
    height: 27.5px;
    padding: 4px;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
}
.greyed .day-square-heading {
    opacity: 0.5;
}
.multi-day-title {
    font-weight: bold;
}
.month-canvas {
    position: absolute;
    left: -1px;
    width: 100%;
    height: 100%;
}
.month-container {
    border: 1px solid #c7d2d9;
    border-bottom: 0;
}
.extra-event-indicator {
    right: 3px;
    top: 3px;
    position: absolute;
    border-radius: 50%;
    background-color: #37a61d;
    width: 27.5px;
    height: 27.5px;
    padding: 4px;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
}
