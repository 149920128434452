.shows-module {
    /*
	 *		Shows list --------------------------------------------------
	 */

    // Table widths ---------------------------------------------
    table.dx-table-shows-list {
        // For delete and edit cells (it depends a bit how many columns are present in the table tho, this is for when max)
        td:nth-child(1) {
            width: 20%;
        }
        td:nth-child(2) {
            width: 30%;
        }
        td:nth-child(3) {
            width: 25%;
        }
        td:nth-child(4) {
            width: 15%;
        }
        td:nth-child(5) {
            width: 5%;
        }
        td:nth-child(6) {
            width: 5%;
        }

        tr {
            border-top: 1px solid #e0e4e7;
            border-bottom: 1px solid #e0e4e7;
        }
    }

    table.shows-purchases-table {
        td:nth-child(1) {
            width: 10%;
        }
        td:nth-child(2) {
            width: 40%;
        }
        td:nth-child(3) {
            width: 20%;
        }
        td:nth-child(4) {
            width: 15%;
        }
        td:nth-child(5) {
            width: 15%;
        }
    }
    // ------------------------------------------------------------

    // The title of the show displayed with italic font and a gray circular label in front
    p.shows-list-title-unpublished {
        font-style: italic;
        font-weight: lighter;
        color: @dx-gray-darkest;

        &:before {
            .dx-font-awesome-pseudo;
            content: '\f111';
            color: @dx-gray;
            margin-right: 8px;
            vertical-align: bottom;
            font-size: 14px;
            @media @dx-mobile-sm {
                font-size: 10px;
            }
        }
    }

    .shows-no-tickets-sold-info {
        margin-left: 15px;
        margin-top: 15px;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 8px 13px;
        display: inline-block;
    }

    // When a show's startDate has passed, it is marked as finished in the list with a dark blue clock icon - no checkmark!
    p.shows-list-title-finished {
        font-weight: normal;
        &:before {
            .dx-font-awesome-pseudo;
            content: '\f00c';
            color: desaturate(@dx-black-medium, 40%);
            margin-right: 8px;
            vertical-align: middle;
            font-size: 14px;
            @media @dx-mobile-sm {
                font-size: 13px;
            }
        }
    }

    p.shows-list-start-time {
        .dx-table .date-column;
        font-size: 14px;
    }

    p.shows-list-finish-time {
        font-size: 12px;
        color: @dx-gray-dark;
        @media @dx-mobile-md {
            display: none;
        }
    }

    p.shows-list-regular-text {
        padding: 10px;
        margin: 0;
    }

    // Cell with bar representing how many tickets are sold and reserved
    .shows-list-sales-stats {
        position: relative;
        height: 25px;

        .hundred-percent,
        .reserved,
        .sold {
            position: absolute;
            top: 0;
            height: 10px;
        }

        .hundred-percent {
            border: 1px solid @dx-gray-lighter;
            left: 0;
            background-color: white;
            width: 100%;
        }

        .sold {
            left: 0;
            background-color: #32c7f7;
        }

        .reserved {
            background-color: #b1e5f6;
        }

        .shows-legend-wrapper {
            text-align: center;

            i.sold-icon,
            i.reserved-icon,
            i.free-icon {
                color: #ccc;
                margin-right: 2px;
                background-color: transparent;
                font-size: 10px;

                &:hover {
                    + p {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
            i.sold-icon {
                color: #32c7f7;
            }
            i.reserved-icon {
                color: #b1e5f6;
            }

            p.shows-sold-legend,
            p.shows-reserved-legend,
            p.shows-free-legend {
                display: inline-block;
                font-size: 10px;
                margin: 12px 5px 0 0;
                color: @dx-gray-dark;
                &:before {
                    background-color: white;
                    padding: 1px 6px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                    color: @dx-text;
                    content: attr(data-content);
                    font-size: 14px;
                    position: absolute;
                    top: -20px;
                    left: 5px;
                    @media @dx-mobile-lg {
                        left: 0px;
                        font-size: 12px;
                        padding: 1px 3px;
                    }
                    @media @dx-mobile-md {
                        left: -5px;
                        font-size: 11px;
                        padding: 1px 2px;
                    }
                    white-space: nowrap;
                    opacity: 0;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    // Explains icons used in shows list
    .shows-filters-legend {
        i {
            font-size: 14px;
            margin-right: 10px;
        }
        i.unpublished {
            color: @dx-gray;
        }
        i.finished {
            color: desaturate(@dx-black-medium, 40%);
        }
    }

    .shows-create-dropdown {
        margin-left: 15px !important;
        @media @dx-mobile-sm {
            margin-left: 5px !important;
        }
    }

    /*
	 *
	 *		Show details    -------------------------------------------
	 *
	 */

    // Used to force the two dates to stack, while remaining inline with the surrounding content
    div.show-header-time-wrapper {
        display: inline-block;
        margin-right: 10%;
        vertical-align: top;
        @media @dx-mobile-md {
            width: 80%;
            width: calc(~'100% - 40px'); // forces the location that comes inline after, to be pushed down
            margin-right: 0;
        }
    }

    // Displaying dates and location for show
    p.show-header-start-time,
    span.show-header-location {
        font-size: 16px;
        color: @dx-gray-lightest;
        font-weight: lighter;
    }

    p.show-header-finish-time {
        font-size: 12px;
        color: #a4a4a4;
    }

    i.show-header-icon {
        color: white;
        font-size: 20px;
        width: 35px;
    }

    .show-sale-stats-number {
        font-size: 35px;
        @media @dx-mobile-md {
            font-size: 20px;
        }
        @media @dx-mobile-sm {
            font-size: 16px;
        }
    }

    .show-facts {
        .cards-fill-sides();
        background-color: @dx-gray-lightest;
        border-bottom: 1px solid @dx-gray;
    }

    // Round icon tooltip displaying whether sale is currently active or not
    .show-sale-status {
        font-size: 22px;
        cursor: pointer;
        position: relative;
        margin-top: 15px;
        display: inline-block;
        background-color: transparent;
        border: none;
        outline: none;

        .sale-active {
            color: #96cd5d;
        }
        .sale-inactive {
            color: #ff8e00;
        }

        &:after {
            content: attr(data-status);
            opacity: 0;
            font-size: 12px;
            color: black;
            display: inline-block;
            margin: 0;
            margin-left: 8px;
            vertical-align: middle;
        }

        &:hover:after,
        &:focus:after,
        &:hover:focus:after,
        &:focus {
            opacity: 1;
        }
    }

    .show-publish-btn {
        margin-top: -10px;
        margin-left: 0;
        @media @dx-mobile-sm {
            margin-top: 20px;
        }
    }

    .show-edit-delete-btn {
        &:hover {
            i {
                color: @dx-secondary;
            }
        }
    }

    /*
	 *	New show / edit show -----------------------------------------------
	 *
	 */

    // On very large screens, the edit area grows too wide
    div.show-edit-container-width {
        float: left;
        width: 1170px;
        margin-bottom: 1000px; // To be able to auto-scroll down to next step, there has to be room
        @media @dx-mobile-lg {
            float: none;
            width: auto;
        }
    }

    // button.new-show-hidden-end-date {
    // 	background-color: @dx-secondary;
    // 	color: white;
    // 	border: none;
    // 	display: inline-block;
    // 	border-radius: 2px;
    // 	padding: 8px 8px;
    // 	margin-top: 24px;
    // 	min-width: 245px;
    // 	&:hover, &:focus {
    // 		color: white;
    // 		background-color: @dx-secondary-dark;
    // 		outline:0;
    // 	}
    // }

    table.show-edit-templates-table {
        td:nth-child(1) {
            width: 50%;
        }
        td:nth-child(2) {
            width: 40%;
        }
        td:nth-child(3) {
            width: 10%;
        }
    }

    table.show-edit-categories-table {
        td:nth-child(1) {
            width: 30%;
        }
        td:nth-child(2) {
            width: 10%;
        }
        td:nth-child(3) {
            width: 20%;
        }
        td:nth-child(4) {
            width: 20%;
        }
        td:nth-child(5) {
            width: 10%;
        }
        td:nth-child(6) {
            width: 5%;
        }
        td:nth-child(7) {
            width: 5%;
        }

        // Hack to make the columns marked not sortable (As Griddle so far doesn't seem
        // to support specifying this per column. It's either no sort or all sort)
        thead {
            th:nth-child(3),
            th:nth-child(4),
            th:nth-child(5),
            th:nth-child(6),
            th:nth-child(7) {
                pointer-events: none;
            }
        }
    }

    .show-edit-location-map-wrapper {
        position: relative;
        // The container for the google maps api where map is loaded
        .map-container {
            width: 100%;
            min-height: 300px;
            margin: 10px 20px 10px 0;
        }
        // Semi-transparent background and message if no address yet selected
        .no-map-overlay {
            position: absolute;
            background-color: rgba(1, 1, 1, 0.5);
            width: 100%;
            height: 100%;
            padding: 4%;
            top: 0;
            left: 0;
            text-align: center;
            color: white;

            span {
                background-color: transparent;
                font-size: 150px;
            }
        }
    }

    span.capacity {
        color: @dx-gray-darker;
    }

    // A message with a geo-icon in front
    .show-edit-location-map-error {
        background-color: #f8f8f8;
        padding: 10px 10px 8px 14px;
        font-size: 14px;

        p {
            margin: 0;

            &:before {
                .dx-font-awesome-pseudo;
                content: '\f05a';
                color: #32c7f7;
                font-size: 20px;
                vertical-align: sub;
                padding-right: 8px;
            }
        }
    }

    .show-text-as-button {
        background-color: transparent;
        border: none;
        outline: none;
        font-weight: bold;
        color: @dx-text;
    }

    .edit-basic-poster {
        .content {
            padding: 0;
            border-radius: 2px;
            margin-top: 8px;
            margin-left: -1px; // hack
            position: relative;
            border: 1px solid @dx-gray-lightest;

            img {
                border-radius: 2px;
                max-width: 250px;
            }

            .hover-overlay {
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 2px;
                color: black;
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                padding-top: 30%;
                border: 1px solid @dx-gray-lightest;
                opacity: 0;

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
    .table-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mode-selector {
            margin: 19px 15px 19px auto;
            align-self: flex-start;
        }
    }
}
