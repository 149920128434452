.culture-mini-calendar {
    user-select: none;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 15px;

    @media @dx-mobile-sm {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0;
    }

    // For collapse
    max-height: 400px;
    overflow: hidden;
    transition: max-height 400ms;

    &.collapsed {
        max-height: 55px;
        transition: max-height 400ms;
    }

    .header {
        display: flex;
        align-items: center;

        .prev,
        .next {
            flex: 0 0 auto;
            cursor: pointer;
            font-size: 25px;
            color: @dx-gray-darkest;
            padding-left: 10px;
            padding-right: 10px;

            &:hover {
                background-color: @dx-gray-lighter;
            }

            &:active {
                background-color: darken(@dx-gray-lighter, 10%);
            }
        }

        .title {
            flex: 1 1 auto;
            text-align: center;
            text-transform: capitalize;
            font-size: 15px;
            color: @dx-gray-darkest;

            // Absolute position the loading indicator so the title don't jump
            // when it appears/disappears.
            .loading-indicator {
                display: inline;
                position: relative;

                .fa {
                    position: absolute;
                    right: -30px;
                }
            }
        }
    }

    table {
        td,
        th {
            width: 50px;
            height: 35px;
            vertical-align: middle;
            text-align: center;
        }

        th {
            color: @dx-gray-darkest;
            font-weight: 600;
            height: 25px;
            vertical-align: bottom;
        }

        .date {
            font-weight: 300;
            cursor: pointer;
            position: relative;

            &:hover {
                background-color: @dx-gray-lighter;
            }

            &:active {
                background-color: darken(@dx-gray-lighter, 10%);
            }

            &.selected {
                background-color: @dx-blue;
                color: white;
                cursor: default;

                &:nth-child(2) {
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px;
                }

                &:last-child {
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                }
            }

            &.today {
                background-color: darken(@dx-gray-lighter, 4%);
                border-radius: 2px;
            }

            &.past {
                color: darken(@dx-gray, 5%);
            }

            .dots {
                position: relative;
                bottom: 3px;
                width: 100%;
                display: flex;
                justify-content: center;
                z-index: 1;

                .dot {
                    flex: 0 0 auto;
                    height: 6px; //9px hvis border white
                    width: 6px; //9px hvis border white
                    border-radius: 9px;
                    background-color: gray;

                    &.event {
                        background-color: #33a717;
                    }

                    &.event-blurred {
                        background-color: #33a717;
                        opacity: 0.2;
                    }

                    &.note {
                        background-color: #f5a623;
                    }
                }

                @media @dx-mobile-sm {
                    bottom: 2px;
                }
            }
        }

        .other-month {
            color: @dx-gray-dark;
            font-weight: 100;

            .dots {
                display: none;
            }
        }

        .red {
            color: @dx-red;
        }

        .week-number {
            color: @dx-gray;
            font-weight: 100;
            font-style: italic;
        }
    }
}
