//
// Bootstrap customizations for DX Web
//

@import '~bootstrap/less/bootstrap.less';

@icon-font-path: '/node_modules/bootstrap/fonts/';
@icon-font-name: 'glyphiconshalflings-regular';
@headings-font-weight: 400;
@font-family-sans-serif: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Colors
@brand-primary: @dx-blue;
@brand-success: @dx-green;

// Customize navbar
@navbar-height: @dx-header-height;
@navbar-inverse-bg: @brand-success;
@navbar-inverse-color: white;
@navbar-inverse-link-color: white;

.navbar-fixed-top {
    left: 200px;
}

@body-bg: @dx-background;
@text-color: @dx-black;

@input-bg-disabled: #aaa;

@border-radius-base: @dx-border-radius;
@border-radius-small: @dx-border-radius-small;
@border-radius-large: @dx-border-radius;

// Bigger tooltips
.tooltip {
    font-size: @font-size-base;
}
.tooltip-inner {
    padding: 5px 10px;
}

// List group
.list-group.list-group-borderless {
    .list-group-item {
        border-left: none;
        border-right: none;
        margin-top: -1px;
        margin-bottom: 0;
    }
}

// Override modal placement so the popups appear a little further down the screen
.modal-dialog {
    margin-top: 5%;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
    font-weight: 600; // Added bold title
    //margin-left: 5px; // This looks good, but might look bad on mobile and I can't be bothered to fix atm
}

li.dropdown {
    list-style: none;
}

ul.dropdown-menu-right {
    right: 0 !important;
    left: inherit;
}

// Override Bootstrap form-group
.form-group {
    margin-bottom: 25px;
}

.popover-warning {
    border-color: @dx-danger;
    padding: 0;
    border-radius: @dx-border-radius;

    &.right {
        .arrow {
            border-right-color: @dx-danger;

            &:after {
                border-right-color: @dx-danger-light;
            }
        }
    }

    .popover-content {
        background-color: @dx-danger-light;
    }
}

.progress {
    margin-bottom: 0;
}

// Extend this behaviour to include smaller screens
@media @dx-mobile-sm {
    .navbar-header {
        float: left;
    }
    .navbar-right {
        float: right !important;
        float: right;
        margin-right: -15px;
    }

    .navbar-text {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
    }
}
