#context-menu {
    .context-menus {
        &-context-menus-choice {
            padding: 10px 10px 5px 10px;
            border-bottom: 1px solid @dx-gray;
            cursor: pointer;
        }
        &-context-menus-choice:first-child {
            padding: 0 10px 10px 10px;
            border-bottom: 1px solid @dx-gray;
            cursor: pointer;
        }
        &-context-menus-choice:last-child {
            padding: 10px 10px 5px 10px;
            border-bottom: none;
            cursor: pointer;
        }
    }
}
