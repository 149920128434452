// mixin that generates @levels of nested '&' selectors
.card-width(@levels, @start-width: 80%, @increments: 10) when (@levels > 0) {
    @selector: '&';
    @{selector} {
        width: @start-width;
        .card-width(@levels - 1, @start-width - @increments);
    }
}
@start-width-large: 80%;
@start-width-small: 90%;
.card-container {
    .card-component {
        .place-below-header-with-top();

        position: fixed;
        background-color: white;
        width: @start-width-large;
        // top: 0;
        right: 0;
        bottom: 0;
        z-index: @dx-z-card;
        box-shadow: 0 40px 77px 0 rgba(0, 0, 0, 0.22);

        // This next line is needed so that the scrollbars of .card-content will
        // be positioned correctly on Chrome using MacOS with retina display
        // without any mouse attached.
        overflow: hidden;

        // Sizes of nested cards:
        .card-width(5, @start-width-large);

        @media @dx-mobile {
            width: @start-width-small;
            top: @dx-header-height-mobile;
            .card-width(5, @start-width-small, 0);
        }

        &.card-enter,
        &.card-appear {
            right: -80%;
        }

        &.card-enter-active,
        &.card-appear-active {
            right: 0;
            transition: all @dx-default-curve @dx-duration-default;
        }

        &.card-exit {
            right: 0;
        }

        &.card-exit-active {
            right: -80%;
            transition: all @dx-default-curve @dx-duration-default;
        }

        .card-content {
            padding: 35px @dx-cards-padding-right 40px @dx-cards-padding-left;
            height: 100%;
            .auto-elastic-scroll();
        }
    }

    .card-close {
        position: absolute;
        left: 10px;
        top: 10px;
        font-size: 20px;
        color: @dx-gray-dark;
    }

    .card-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: black;
        opacity: 0.3;
        cursor: pointer;
        z-index: @dx-z-card;

        // Animate the overlay:
        &.card-enter,
        &.card-appear {
            opacity: 0;
        }

        &.card-enter-active,
        &.vard-appear-active {
            opacity: 0.3;
            transition: opacity @dx-default-curve @dx-duration-default;
        }

        &.card-exit {
            opacity: 0.3;
        }

        &.card-exit-active {
            opacity: 0;
            transition: opacity @dx-default-curve @dx-duration-default;
        }
    }
}
