///////////////// Mixins ///////////////////////////////

// Standard input with blue crisp outline on focus
.dx-input-defaults {
    position: relative;
    width: 100%;
    height: 34px;
    padding: 6px 8px;
    font-size: 16px;
    line-height: 100%;
    background-color: white;
    border: 1px solid @dx-gray;
    .dx-border-radius;
    -webkit-appearance: none;
    outline: none;
    display: inline-block;
    cursor: pointer;

    &:focus {
        border: 1px solid @dx-secondary;
        cursor: text;
    }
}

// Hover icon for input
.dx-input-icon-defaults {
    display: inline;
    position: absolute;
    background-color: #f0f0f0;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    padding-top: 9px;
    width: 35px;
    height: 34px;
    right: 0;
    bottom: 0;
    top: 0;
    border: 1px solid @dx-gray;
    visibility: hidden;
    pointer-events: none;
    text-align: center;
}
///////////////////////////////////////////////////////////

.input-wrapper,
.input-wrapper-lg,
.dx-input-wrapper-time,
.select-wrapper,
.textarea-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 25px;

    .input-icon {
        .dx-input-icon-defaults;
    }

    input[type='text'],
    input[type='time'],
    input[type='password'],
    select,
    textarea {
        .dx-input-defaults;

        &:hover {
            + span {
                visibility: visible;
            }
        }

        &:focus:hover {
            + span {
                visibility: hidden;
            }
        }

        &:disabled {
            background: @dx-gray-lightest;
            cursor: text;
        }
    }

    input[type='date'] {
        // Don't show the span overlay on date because it has it's own dropdown thingy
        .dx-input-defaults;
    }

    input[type='number'] {
        .dx-input-defaults;
        &:disabled {
            background: @dx-gray-lightest;
            cursor: text;
        }
    }
}

// Time input needs slightly wider icon to hide the default
.dx-input-wrapper-time {
    .input-icon {
        .dx-input-icon-defaults;
        width: 38px;
    }
}

// Large version of input
.input-wrapper-lg {
    margin-bottom: 25px;

    .input-icon {
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        padding-top: 14px;
        font-size: 30px;
        width: 60px;
    }

    input[type='text'] {
        height: 60px;
        padding: 10px 10px;
        font-size: 35px;
        line-height: 100%;
        border-radius: 2px;
    }
}

// Some special styling for textarea only
.textarea-wrapper {
    .input-icon {
        background-color: transparent;
        border: none;
    }

    textarea {
        min-height: 100px;
        max-width: 100%;
    }
}

// Some special styling for selection dropdown
.select-wrapper {
    .input-icon {
        visibility: visible;
    }
    select:focus:hover,
    select:hover {
        border: 1px solid @dx-gray;
        + span {
            visibility: visible;
            color: @dx-secondary;
        }
    }
}

// Standalone clear button to be added to input fields etc
.dx-clear-input-btn {
    display: inline-block;
    color: white;
    font-size: 14px;
    vertical-align: middle;
    background-color: @dx-secondary;
    padding: 0px 6px;
    border-radius: 2px;
    cursor: pointer;
}

// Standard search box
.dx-search-box {
    // float: right;
    position: relative;
    display: block;
    // margin-left:10px;
    @media @dx-mobile-xs {
        font-size: 12px;
    }

    a {
        display: inline-block;
        color: white;
        text-decoration: none;
        font-size: 14px;
        vertical-align: middle;
        margin-left: 5px;
        margin-top: -2px;
        background-color: @dx-secondary;
        padding: 1px 6px 0px 6px;
        border-radius: 2px;
    }

    input {
        .dx-input-defaults;
        // width: initial;
        // max-width: 250px;
        vertical-align: middle;
    }
}
