.rdw-option-wrapper {
    // Buttons should have the same size as the dropdown selector
    height: 30px;
    min-width: 30px;
}

.rdw-dropdown-selectedtext {
    // Don't want blue text on selected blocktype
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}
