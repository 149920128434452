.page-layout-with-filters {
    // Fill window (below header)
    position: absolute;
    // .place-below-header-with-top();
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    @media print {
        bottom: auto;
    }

    .plwf-header {
        flex: 0 0 auto;
        border-bottom: 1px solid darken(@dx-gray-lightest, 5%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;

        padding: 18px 20px;
        @media @dx-mobile-sm {
            // padding: 5px;
            padding: 5px 10px;
        }

        h1 {
            flex: 0 1 auto;
            overflow: hidden; // Prioritize space for buttons
            margin: 0;
            font-size: 30px;
            font-weight: 400;
            @media @dx-mobile-sm {
                font-size: 24px;
            }
            @media @dx-mobile-xs {
                font-size: 18px;
            }
        }

        // Buttons should be aligned to right
        .plwf-buttons {
            flex: 0 0 auto;

            // Filter button should be hidden unless we are on a small screen
            .filterButton {
                display: none;
                // margin-right: 15px;

                @media @dx-mobile-lg {
                    display: inline;
                }
            }
        }
    }
    .plwf-content {
        flex: 1 1 auto;
        flex-direction: row;
        display: flex;
        overflow: hidden;
        background-color: white;

        .plwf-sidebar {
            flex: 0 0 300px;
            padding: 2rem;
            .auto-elastic-scroll();

            // background-color: @page-sidebar-bg-color;
            border-right: 1px solid @dx-border-color;

            // The filter header should not be visible if filters is a sidebar (big
            // screen)
            .plwf-filterHeader {
                display: none;
            }

            @media print {
                display: none;
            }

            // On smaller screens we should display filters as an absolute positioned
            // popup.
            @media @dx-mobile {
                &.plwf-hideFilterPopup {
                    display: none;
                }

                .plwf-filterHeader {
                    display: block;
                    margin-bottom: 10px;

                    h1 {
                        font-size: 2rem;
                        margin-top: 0;
                        display: inline-block;
                    }

                    .btn {
                        float: right;
                        cursor: pointer;
                    }
                }

                position: absolute;
                background-color: white;
                left: 30%;
                right: 30%;
                top: 65px;
                border: 1px solid @dx-gray;
                .paper-shadow-top-z-5;
                z-index: @dx-z-filters-popup;
            }

            @media @dx-mobile-sm {
                left: 20px;
                right: 20px;
            }

            @media @dx-mobile-xs {
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .plwf-contentContent {
            flex: 1 1 auto;
            // padding: 0 1rem 1rem 1rem;
            .auto-elastic-scroll();

            @media @dx-mobile-sm {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
