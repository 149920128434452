.splash-page {
    // Fill the whole screen
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @dx-z-splash-page;

    background-image: url('/img/login_bg.jpg');
    background-size: cover;
    background-position: center center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // Box centered in the screen
    .center-box {
        flex: 0 0 auto;
        width: 320px;
    }

    .dx-logo {
        background-image: url('/img/login_logo.png');
        background-position: center center;
        background-repeat: no-repeat;
        height: 100px;
    }

    // Material design like card (the white background in login form)
    .card {
        background-color: white;
        .paper-shadow-bottom-z-5;
        padding: 20px;
        margin-bottom: 10px;
    }
}

#dx-logo {
    display: block;
    width: 200px;
    height: 20px;
    margin-right: 0;
    position: absolute;
    float: right;
    text-align: right;
    color: white;
    text-decoration: none;
    bottom: 10px !important;
    right: 10px !important;
}
