@easing: cubic-bezier(0.55, 0, 0.1, 1);

.expander-box {
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    background-color: white;
    border-radius: 2px;
    max-width: 100%;
    width: 100%;
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        p {
            margin: 0;
        }
    }
    &__subtitle {
        padding: 5px;
        color: #999;
        position: relative;
        cursor: pointer;
        margin: 0;
        .caret {
            color: #071c2b;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 0.3s @easing;
            .open & {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    &__content {
    }
    &__expandable {
        padding: 0 5px;
        height: 0;
        overflow: hidden;
        transition: height 0.3s @easing;
        div {
            padding: 1px;
        }
    }
}
