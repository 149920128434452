@sidebar-color: #394a52;

// Expand/collapse transition
//
// These transition rules are added programmatically in Sidebar.jsx
// componentDidMount so we avoid animations triggered on page load.
//
// .main, .navbar { transition: left 250ms; }
// .main-sidebar { transition: width 250ms, left 250ms; }
// .main-sidebar-label { transition: opacity 250ms; }

// When sidebar is collapsed (on desktop), it is 50 px wide:
.sidebar-collapsed {
    .main,
    .navbar {
        left: 50px;
    }
    .main-sidebar {
        width: 50px;
    }
    .main-sidebar-label {
        opacity: 0;
    }
}

// But on mobile, the sidebar should float over the rest of the page. So it
// never takes up space from .main and .navbar
.sidebar-mobile {
    .main,
    .navbar {
        left: 0;
    }
    #main-navbar {
        .navbar-header {
            margin: 0;
        }
    }
}

// And when it is collapsed om mobile, it should just be moved out of the screen
// to left:
.sidebar-mobile.sidebar-collapsed {
    .main-sidebar {
        left: -200px;
    }
}

// The toggle button for opening and close the sidebar
.sidebar-toggle {
    color: white;
    position: absolute;
    top: 5px;
    left: 155px;
    padding: 10px 15px 10px 10px;
    font-size: 13px;
    z-index: 10000;
    transition: left 0.25s cubic-bezier(0.2, 0, 0.1, 1);
    @media @dx-mobile-sm {
        transition: left 0.25s cubic-bezier(0.01, 0.97, 0.74, 0.65);
    }
    .collapsed & {
        left: 7px;
    }
}

// When the toggle button is placed inside the menu, it should float to right
.main-sidebar .sidebar-toggle {
    right: 0;
    transform: translate(0, 0, 0);
    will-change: left;
}

// but when toggle button is used in the navbar, it should float to left
.navbar .sidebar-toggle {
    top: 0;
    left: 0;

    .fa-caret-left {
        display: none;
    }
}

.sidebar-toggle {
    padding: 18px 13px 18px 17px;
    left: 145px;
    top: 0;
    z-index: 1039;
    transition: left 0.25s cubic-bezier(0.2, 0, 0.1, 1);
    @media @dx-mobile {
        font-size: 20px;
        line-height: 1;
        height: 20px;
        padding: 15px 15px 31px 15px;
        left: 140px;
    }
}
.collapsed {
    .sidebar-toggle {
        left: 0;
    }
}

.sidebar-mobile {
    .navbar {
        // Content of navbar (title and username) should be pushed to make room
        // for burger menu on mobile
        .container-fluid {
            margin-left: 30px;
        }
    }
}

.main-sidebar {
    background-color: @sidebar-color;
    // Remove scrollbars in sidebar in Internet Explorer (IE 10+)
    -ms-overflow-style: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    z-index: @dx-z-sidebar;
    overflow-x: hidden;

    padding-top: @navbar-height;
    box-shadow: inset -7px 0 20px -7px darken(@sidebar-color, 10%);

    li {
        white-space: nowrap;
    }

    .nav a {
        border-left: 8px solid transparent;
        padding-left: 5px;

        color: white;
    }
    .nav-pills > li.active > a:focus {
        background-color: lighten(@sidebar-color, 10%);
    }
    .nav-pills > li.active > a:hover {
        background-color: @dx-blue;
    }
    .nav-pills > li > a:active {
        background-color: @dx-blue;
    }
    .nav a:hover,
    .nav a:focus {
        background-color: lighten(@sidebar-color, 10%);
        box-shadow: inset -7px 0 20px -7px darken(@sidebar-color, 10%);
    }

    .nav .active a {
        background-color: lighten(@sidebar-color, 10%);
        box-shadow: inset -7px 0 20px -7px darken(@sidebar-color, 10%);
        border-left: 8px solid @brand-primary;
    }

    .nav .active.partner a {
        border-left: 8px solid transparent;
    }

    #announcekit-badge-text {
        color: white;
        span {
            padding: 10px 15px 10px 15px;
        }
        a {
            border: none;
        }
    }
    .user-context-menu {
        > a {
            padding-left: 25px;
            position: relative;
            display: block;
            &:hover {
                box-shadow: none;
            }
            &:before {
                position: absolute;
                top: 9px;
                left: 9px;
                font-family: 'FontAwesome';
                content: '\f007';
            }
            &:after {
                position: absolute;
                font-family: 'FontAwesome';
                content: '\f0d7';
                right: 20px;
                top: 9px;
                transition: transform 0.25s, opacity 0.25s;
            }
        }
        .collapsed & {
            > a {
                &:after {
                    opacity: 0;
                }
            }
            &.expanded {
                background-color: @sidebar-color;
                ul {
                    padding: 0;
                    height: 0;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            height: 0;
            overflow: hidden;
            li {
                display: block;
                padding-top: 5px;
                &:first-child {
                    padding-top: 0;
                }
                //margin: 0;
            }
        }
        &.expanded {
            background-color: #4e6570;
            > a {
                &:after {
                    transform: rotate(180deg);
                }
            }
            ul {
                padding: 0 0 10px 20px;
                height: auto;
            }
        }
        .main-sidebar-label {
            &__display-name {
                width: ~'calc(100% - 20px)';
                padding-left: 2px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
