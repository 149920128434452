.content-with-sidebar {
    .cws-header {
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        background-color: white;
        padding: 18px 20px;
        @media @dx-mobile-sm {
            padding: 5px 10px;
        }

        h1 {
            // width: calc(~"100% - 300px");
            // overflow: hidden;
            // text-overflow: ellipsis;
            margin: 0;
            font-size: 20px;
            padding: 8px 10px 0px 0;
            @media @dx-mobile-sm {
                font-size: 20px;
            }
            @media @dx-mobile-xs {
                font-size: 18px;
            }
        }

        .cws-header-content {
            max-width: 300px;
            text-align: right;
        }
    }

    .cws-content {
        background-color: white;
        overflow-x: hidden;

        // Customize a bit the styling of calendar for use in a sidebar
        .calendar-mini-view {
            width: auto;
            padding: 5px;
        }
    }
}
