.logo-upload-box {
    padding: 40px 31px 32px 26px;
    background-color: @dx-gray-lightest;
    border: 1px solid @dx-gray-lighter;
    cursor: pointer;
    margin-bottom: 55px;
}

.partner-settings {
    background-color: white;

    .users-table {
        margin: 15px 0 60px 0;

        @dx-mobile-xs {
            margin: 15px 0 25px 0;
        }

        td:nth-child(1) {
            width: 5%;
        }
        td:nth-child(2) {
            width: 35%;
        }
        td:nth-child(3) {
            width: 60%;
        }

        td.name {
            font-weight: 600;
            font-size: 16px;
            color: @dx-text;

            @dx-mobile-sm {
                font-size: 14px;
            }
        }

        td.role {
            color: @dx-gray-darker;
            font-size: 14px;

            @dx-mobile-sm {
                font-size: 13px;
            }
        }
    }

    .user-role-access {
        border-radius: 2px;
        padding: 15px 25px;
        border: 1px solid #ccc;
        margin-right: 15px;

        // Stack column when small screen / mobile
        @media @dx-mobile-sm {
            margin-bottom: 25px;
            padding: 5px 15px;
            margin-right: 0;
        }

        .radioStyle {
            span {
                font-weight: 600;
                font-size: 16px;
            }
        }

        h3 {
            margin-top: 10px;
        }

        i {
            font-size: 10px;
            padding-right: 5px;
        }

        .indented {
            margin-left: 10px;
        }

        &.selected {
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            border: 1px solid @dx-gray;

            h3 {
                position: relative;
                &:after {
                    content: '\f046';
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    color: @dx-secondary;
                    font-size: 22px;
                    position: absolute;
                    padding-left: 10px;
                    top: 1px;
                }
            }
        }
    }
}

// @-moz-document url-prefix() {
//     overflow: hidden; // cause firefox keeps bugging out with margins
// }
