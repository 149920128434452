.calendar-mode-selector {
    display: flex;
    cursor: pointer;
    justify-content: center;
    width: 100%;

    @media @dx-mobile-sm {
        margin-top: 10px;
    }

    .mode {
        cursor: pointer;
        padding: 10px 15px;
        background-color: white;
        color: @dx-black;
        .paper-shadow-bottom-z-1;

        &:first-child {
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
        }

        &:last-child {
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
        }

        p {
            margin: 0;
        }

        &:hover {
            background-color: @dx-blue-light;
        }

        &.active {
            background-color: @dx-blue;
            color: white;
        }

        &.disabled {
            background-color: transparent;
            color: @dx-gray;
            cursor: not-allowed;
        }
    }
}
