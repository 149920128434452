.calendar-filter-view {
    .colored-checkable-item {
        width: 14px;
        height: 14px;
        margin-top: 3px;
        line-height: 24px;
        border-width: 3px;
        border-style: solid;
        float: left;
        margin-right: 6px;
    }

    li {
        line-height: 24px;
        cursor: pointer;

        * {
            cursor: pointer;
        }
    }
}
