.tono-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease;
    &.tono-header {
        font-weight: bold;
        border-bottom: 1px solid @dx-gray;
    }
    &.tono-hidden {
        opacity: 0.5;
    }
    height: 36px;
}
.tono-border {
    border-bottom: 1px solid @dx-gray;
}
.tono-header > div {
    white-space: normal;
    overflow: visible;
}
.tono-row > div {
    //outline: 1px solid red;
    width: 55/7%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tono-row > .component-input-checkbox,
.tono-row > .tono-checkbox-header {
    width: 5/2%;
    overflow: visible;
}
.tono-row > .type-text {
    width: 20% !important;
}
.tono-text {
    &.tono-text-input {
        width: 20%;
    }
}
.tono-text-artist {
    width: 20% !important;
}
.tono-row > .tono-text {
    text-align: left;
}
.tono-row > .tono-number {
    text-align: right;
}

.tono-row > .components-input > div {
    //padding-bottom: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.tono-row > .components-input {
    position: relative;
}

.tono-instructions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 120%;
    flex-wrap: wrap;
}
.tono-badge::before {
    content: ' \25CF';
    font-size: 100px;
    color: @dx-green;
}
.tono-badge {
    position: relative;
    margin-right: 10px;
}
.tono-inner-badge {
    width: 0;
    height: 0;
    overflow: visible;
    top: 56px;
    right: 41px;
    position: absolute;
    color: white;
    font-size: 140%;
}
.tono-instruction-column {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 320px;
    flex-grow: 1;
}
.tono-report-table {
    //min-width: 1200px;
}
.tono-report-table-scroll-container {
    //overflow-x: auto;
    //overflow-y: auto;  disabled forel0pig, ikke noe god l0sning
}
/*.tono-instruction-column:nth-child(2){
  flex-basis: 30%;
}*/
