.input-duration {
    display: flex;
    flex-direction: row;

    div {
        margin-right: 15px;
        // width: 45px;
        width: 100%;
        position: relative;

        &:after {
            position: absolute;
            font-size: 11px;
            color: @dx-gray-dark;
            top: 100%; //115% looks better, but will make the input rather tall
            left: 0;
        }
    }
    .input-duration-hours-wrapper {
        &:after {
            content: 'timer';
        }
    }

    .input-duration-minutes-wrapper {
        &:after {
            content: 'minutter';
        }
    }

    .input-duration-seconds-wrapper {
        &:after {
            content: 'sekunder';
        }
    }
}
