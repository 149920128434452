@focusContainerAnimation: 0.2s ease-out;
.dropdown-location-entry.no-results {
    color: @dx-gray;
}
.location-input-container {
    position: absolute;
    background-color: @ddColor;
    width: ~'calc(100% + 1em)';
    z-index: 1;
    margin-top: -0.5em;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all @focusContainerAnimation, box-shadow 1ms;
    margin-left: -0.5em;
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    &.has-focus {
        border-radius: 3px;
        border: 1px solid #ccc;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        width: ~'calc(100% + 2em)';
        margin-left: ~'calc(2px - 1em)'; // 15
        padding-left: 0;
        padding-right: 0;

        margin-top: -1em;
        padding-top: 1em;
    }
}
.location-input-label {
    padding-left: 15px;
}
@ddColor: white;
.dx-label {
    transition: margin-left @focusContainerAnimation;
    &.has-focus {
        margin-left: ~'calc(1em - 2px)';
    }
}
.location-input-error {
    margin-left: 1em;
    z-index: -1;
    opacity: 0;
    transition: margin @focusContainerAnimation;
    position: relative !important;
    height: 1.5rem;
    &.has-focus {
        margin-left: 0;
        opacity: 1;
        display: block;
    }
}
.location-input-dropdown-search {
    color: #555555;
    //margin-top: -6px;
    margin-top: 0;
    &.dhidden {
        display: none;
    }
}
.location-input-dropdown-browse {
    background-color: @ddColor;
    &.dhidden {
        display: none;
    }
}
.dropdown-container {
    overflow-y: auto;
    max-height: ~'calc(100vh - 5vw - 235px - 16px - 15px)';
    margin-top: ~'calc(-1em - 3px)';
    @media @dx-mobile-sm {
        max-height: ~'calc(100vh - 20vw - 100px)';
    }
    @media @dx-mobile-md {
        max-height: 300px;
    }
}

.location-input-input-input {
    height: 24.5px; // measured n for use by absolute children
    padding-left: 0;
    transition: padding-left @focusContainerAnimation;
    &.with-overlay {
        padding-left: 38px;
    }
}
.location-input-input {
    height: 24.5px; // measured n for use by absolute children
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
    font-weight: 500;
    transition: padding @focusContainerAnimation;
    &.has-focus {
        padding-right: ~'calc(0.75em + 1px)';
        padding-left: ~'calc(0.75em + 1px)';
    }
}
.location-input-dropdown-search > .dropdown-location-entry:first-of-type {
    border-top: none;
}
.location-input-dropdown-search > .dropdown-location-entry {
    padding-left: 0.75em;
}
// ---- entrys
.dropdown-location-group {
    height: 50px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    font-weight: bold;
    width: 100%;
    flex-direction: row;
    cursor: pointer;
}
.dropdown-location-entry {
    padding-left: ~'calc(50px + 0.75em)';
    margin-left: 0;
    height: 40px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    cursor: pointer;
    &.extra-spacing {
        margin-bottom: 0.5em;
    }
}
// - end entrys
.dropdown-location-most-used-label {
    font-size: 11px;
    color: #838d95;
    margin: 10px 20px 3px;
}
/* Targets .dropdown-location-entry's following .dropdown-location-most-used-label */
.dropdown-location-most-used-label ~ .dropdown-location-entry {
    border: none;
    padding-left: 20px !important;
    margin-top: 0;
    height: 2em;
}

.dropdown-location-group-arrow {
    width: 50px;
    height: 50px;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    margin-right: 0.75em;
    font-size: 2rem;
    justify-content: center;
    color: @dx-gray-darkest;
}

.dropdown-location-group-text {
    width: ~'calc(100% - 50px)';
}
.dropdown-location-group-location-container {
    background-color: #fafafa;
}

.location-entry-text {
    &.location-hl {
        color: @dx-gray-darkest;
        font-weight: bold;
    }
}
.dropdown-location-group:hover,
.dropdown-location-group.keyboard-highlighted,
.dropdown-location-entry.keyboard-highlighted,
.dropdown-location-entry:hover {
    background-color: @dx-select-hover;
}

.dropdown-location-entry.no-results:hover {
    background-color: white;
}

.location-entry-title {
    padding-left: 0;
}
.location-entry-has-screen {
    text-align: right;
    color: @dx-gray-dark;
}
.location-input-overlay > .location-entry-capacity {
    padding-right: 0.25em;
}
.location-entry-capacity {
    text-align: right;
    margin-left: 0;
}
.location-entry-capacity > span > .fa-users {
    color: @dx-gray-dark;
}
.location-inputwrapper {
    z-index: 20;
    background-color: white;
}

.dropdown-location-group-location-count {
    color: @dx-gray-dark;
    font-weight: 100;
    letter-spacing: 1px;
}
.location-input-overlay {
    //position: absolute;
    //color: @dx-gray-dark;
    //font-size: 11px;
    //height: 0;
    //position: relative;
    display: flex;
    align-items: center;
    margin-top: -24.5px;
    width: 100%;
    pointer-events: none;
    height: 24.5px;
}
.location-entry-id {
    color: @dx-gray-dark;
    font-size: 11px;
    align-self: flex-end;
    height: 19px;
    padding-left: 0;
    margin-right: 0.5em;
}
.dropdown-location-entry {
    &.disabled {
        color: @dx-gray;
        .location-hl {
            color: @dx-gray;
        }
    }
}
