//
// Style sheet for @media print
//

@media print {
    #sidebar {
        display: none;
    }

    // No scrollbars and fixed cards on print!
    .main,
    .cards-pages {
        left: 0 !important;
        top: 0 !important;
        overflow: visible;
    }

    // Less margins and borders
    .content,
    .card-region .container,
    .cards-pages,
    .base-region {
        margin: 0;
        padding: 0;
        border: none;
    }

    .content {
        margin: 0 30px;
    }

    .card-container .card-component {
        background-color: white !important;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }

    // Hide some ui controllers
    .context-menu,
    .hidden-print {
        display: none !important;
    }

    // No page break inside
    .dx-widget,
    tr {
        page-break-inside: avoid;
    }
    td {
        font-size: 12px;
    }
}
