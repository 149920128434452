/**
 * The geosuggest module
 */
.geosuggest {
    position: relative;
    display: inline-block;
    width: 100%;
}
.geosuggest__input {
    .dx-input-react-defaults;
}
.geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
    color: @dx-gray-darkest;
    background-color: white;
    // border: 1px solid @dx-gray;
    border-radius: 2px;
    margin-top: 3px;
    overflow-x: hidden;
    .auto-elastic-scroll();
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.geosuggest__suggests--hidden {
    display: none;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest-item {
    font-size: 14px;
    padding: 5px 15px;
    &:first-child {
        padding: 10px 15px;
    }
    cursor: pointer;
    &:hover:focus {
        background: @dx-primary;
    }
}

.geosuggest-item--active,
.geosuggest-item:hover {
    background: @dx-primary;
    color: #fff;
    // &:hover:focus { background: @dx-secondary; }
}
