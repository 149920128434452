.page-layout {
    position: absolute;
    // Fixes Chrome bug where fixed elements keep jumping around on non-retina
    // screens and chrome versions - seems to be a known bug in Chrome
    // when loads of things are happening in the browser at once, specially animations
    // This kicks off hardware accelerated rendering NB! Must not be used everywhere. WOrks now with this hack
    // in only appbar.less and page-layout.less ....
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    // HACK! This is stupid, I know. And it probably don't work well on mobile
    // (the padding should be different for different screen sizes). I'll leave
    // that to later, but we need this because we cannot scroll to bottom if we
    // don't do it.
    //
    // We should use FlexBox for page layout instead. That will make things a
    // lot less hacky.
    padding-bottom: 122px;

    background-color: white;
    width: 100%;
    height: 100%;

    // .place-below-header-with-top();
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .page-layout-header {
        position: relative;
        background-color: white;
        width: 100%;
        padding: 18px 20px 7px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid darken(@dx-gray-lightest, 5%);
        z-index: 1;
        @media @dx-mobile-md {
            padding: 18px 15px 0px 15px;
        }
        @media @dx-mobile-sm {
            padding: 18px 10px 0px 10px;
            border-bottom: none;
        }

        h1 {
            display: inline-block;
            margin: 10px 5px; // <--- maybe
            @media @dx-mobile-md {
                margin: 0 0 15px 0;
            }

            font-size: 30px;
            font-weight: 400;
            color: @text-color;
            @media @dx-mobile-lg {
                font-size: 30px;
            }
            @media @dx-mobile-sm {
                font-size: 24px;
            }
            @media @dx-mobile-xs {
                font-size: 21px;
            }
        }

        button {
            float: right;
            @media @dx-mobile-md {
                margin-top: -5px;
            }
        }

        .button-wrapper-delux {
            display: inline-block;
            float: right;
            margin-top: 8px;
            @media @dx-mobile-md {
                margin: 0;
            }
            @media @dx-mobile-sm {
                margin: -5px 0 0 0;
            }

            .page-layout-minimized-filters-wrapper {
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin-right: 12px;
                @media @dx-mobile-sm {
                    margin-right: 5px;
                }

                .page-layout-minimized-filters-container {
                    background-color: white;
                    min-width: 300px;
                    display: none;
                    position: absolute;
                    overflow-y: auto;
                    overflow-x: hidden;
                    right: 0;
                    top: 40px;
                    -webkit-box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.35);
                    -moz-box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.35);
                    box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.35);

                    @media @dx-mobile-sm {
                        top: 30px;
                    }
                    z-index: 10;
                    padding: 20px 15px 40px 15px;
                    border-radius: 2px;
                    border: 1px solid @dx-gray;
                    right: 0;
                    z-index: 10;
                    // > * {
                    // 	margin-bottom: 200px;
                    // }
                }
            }

            .page-layout-context-menu {
                position: relative;
                display: inline-block;
            }
        }
    }
    // To remove scrollbars from sidebar
    .outer-container {
        width: 300px;
        height: 100%;
        display: inline-block;
        float: left;
        position: relative;
        overflow: hidden;
        @media @dx-mobile-lg {
            width: 250px;
        }

        .inner-container {
            position: absolute;
            left: 0;
            .auto-elastic-scroll();
            overflow-x: hidden;
            // height: 100%;

            .page-layout-filters {
                display: inline-block;
                float: left;
                width: 300px;
                background-color: white;
                padding: 20px 10px;
                @media @dx-mobile-lg {
                    width: 250px;
                }

                h3 {
                    font-size: 17px;
                }
            }
        }
        // Removing sidebars in webkit browsers
        .inner-container::-webkit-scrollbar {
            display: none;
        }
    }

    .page-layout-main {
        width: 100%;
        .auto-elastic-scroll();
        height: 100%;
        padding: 0 10px 10px 0; //10px margin-left to make scrollbar fit
        @media @dx-mobile-lg {
            padding: 0 0 100px 0;
        }
        @media @dx-mobile-sm {
            padding: 0 0 100px 0;
        }
    }

    .page-layout-main-pushed-right {
        width: calc(~'100% - 300px') !important;
        margin-left: 300px;
        @media @dx-mobile-lg {
            width: calc(~'100% - 250px') !important;
            margin-left: 250px;
        }
    }
}

html.ninja-mode .page-layout {
    top: (43px + 40px);
}

@-moz-document url-prefix() {
    .page-layout {
        padding-bottom: 150px; // Weird firefox issue where everything clings to underneath the bottom of page
    }
}
