.fx-notification-container {
    z-index: 3000;
    position: fixed;
    background-color: white;
    right: 20px;
    bottom: 20px;
    padding: 10px;
    border-left: 5px solid @dx-green;
    border-radius: 5px;
    animation: fx-notification-slide-up 2s ease;
    .dx-button {
        margin-right: 15px;
        border-radius: 5px;
    }
    .fx-notification-remind-me-later {
        font-size: 1.2em;
        color: @dx-blue;
    }
    .paper-shadow-top-z-1;
}

@keyframes fx-notification-slide-up {
    0% {
        transform: translateY(145px);
    }
    100% {
        transform: translateY(0);
    }
}
