.rendered-shortcut {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 400;

    .rendered-shortcut-keys {
        margin-top: 5px;
        display: flex;
        align-items: center;
        //font-size: 14px;
        i,
        div,
        img {
            margin-right: 15px;
        }
        i {
            font-size: 12px;
        }
        img {
            height: 18px;
        }

        .keyboard_button_space {
            width: 150px;
            height: 36px;
        }

        .keyboard_button {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 8px;
            background-color: #f3f4f8;
            color: @dx-gray-darkest;
            border-radius: 8px;

            //	Button depth simulation
            border-bottom: 4px solid #bfc2cb;
        }

        .keyboard_button_special {
            width: 60px;
            height: 36px;
        }

        .keyboard_button_normal {
            width: 36px;
            height: 36px;
        }
    }

    .align_center {
        justify-content: center;
        display: flex;
    }

    .subtle_text {
        opacity: 0.4;
        font-style: italic;
        font-weight: normal;
    }

    h4 {
        font-weight: bolder;
        margin-bottom: 3px;
    }
}
