.dx-label {
    display: block;
    font-weight: normal;
    color: @dx-gray-darker;
    display: inline-block;
    @media @dx-mobile-sm {
        font-size: 11px;
    }
}

.dx-label-lg {
    .dx-label;
    font-size: 18px;
    display: inline-block;
    @media @dx-mobile-sm {
        font-size: 13px;
    }
}
