.context-menu-global-button,
.context-menu-global-entries {
    .context-menu-ellipsis,
    .context-menu-button {
        &:hover {
            background-color: @dx-gray;
        }
        &:active {
            background-color: @dx-gray-darker;
        }
    }
    .context-menu-ellipsis-open {
        background-color: @dx-gray;
    }
    .context-menu-entry {
        padding: 5px;
    }
}
