.chosen-container-single .chosen-single {
    // display: block;
    // overflow: hidden;
    // padding: 0 0 0 8px;
    // border: 1px solid #ccc;
    // border-radius: 2px;
    // background-color: #fff;
    // background:#fff;
    // color: #444;
    // text-decoration: none;
    // white-space: nowrap;
    // line-height: 32px;
    // height: 100%;
    // box-shadow: none;
    // padding-right: 34px;   //<---- Uncomment for old normal version. The one below is thinner

    display: block;
    overflow: hidden;
    padding: 6px 34px 6px 8px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #fff;
    background: #fff;
    color: @dx-text;
    text-decoration: none;
    white-space: nowrap;
    line-height: 1.42;
    height: 100%;
    box-shadow: none;
}

.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100% !important;
}

.chosen-container-active .chosen-single {
    border: 1px solid @dx-secondary;
    box-shadow: none;
}
.chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff;
    box-shadow: none;
}

.chosen-container-single .chosen-single div {
    display: none;
}

.chosen-container-active .chosen-choices li.search-field input[type='text'] {
    border-radius: 2px;
    margin-top: -2px;
    width: 101%;
    margin-left: -1px;
}

.chosen-container .chosen-drop {
    position: absolute;
    z-index: 1010;
    width: 100%;
    border: 1px solid #ccc;
    border-top: 0;
    background: #fff;
    box-shadow: none;
}

.chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    border-radius: 2px;
    margin-top: -1px;
    margin-right: -1px;
    margin-left: -1px;
    padding: 0;
    white-space: nowrap;
}

.chosen-container .chosen-results {
    color: #444;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    max-height: 200px;
    -webkit-overflow-scrolling: touch;
}

.chosen-container .chosen-results li.highlighted {
    background-color: @dx-secondary;
    background: @dx-secondary;
    color: #fff;
}

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
    display: block;
    overflow: hidden;
    padding: 0 0 0 8px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #fff;
    background: #fff;
    color: #444;
    text-decoration: none;
    white-space: nowrap;
    line-height: 32px;
    height: 100%;
    box-shadow: none;
    padding-right: 34px;
}

.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 5px 5px 3px 0;
    padding: 3px 20px 3px 5px;
    border: 1px solid #aaa;
    max-width: 100%;
    border-radius: 3px;
    background-color: #eee;
    background: #eee;
    box-shadow: none;
    color: #333;
    line-height: 13px;
    cursor: default;
}

.chosen-container-multi .chosen-choices li.search-choice span {
    word-wrap: break-word;
    background-color: #eee;
}
