// override default color for context-item (defined in global.less)
.treeview-context-menu-item:hover {
    background-color: @dx-gray-lighter !important;
}
.treeview-container {
    word-break: break-all;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    .sortable {
        color: @dx-blue;
        &__icon {
            padding-left: 3px;
            .fa {
                font-size: 10px;
                color: @dx-blue !important;
            }
        }
    }

    .expande-collapse-icon {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .header {
        padding-left: 7px;
        padding-right: 7px;
    }
    .cell {
        padding-left: 7px;
        padding-right: 7px;
        display: flex;
        &__label {
            padding-left: 5px;
            opacity: 0.5;
        }
        &__number {
            justify-content: flex-end;
        }
        .components-input {
            padding-bottom: 0;
            width: 90%;
        }
    }
    .treeview {
        display: flex;
        flex-direction: column;

        &__headers {
            min-width: min-content;
            padding-top: 10px;
            padding-bottom: 10px;
            display: inline-flex;
            flex-direction: row;
            font-style: italic;
            font-size: 14px;
            border-bottom: 1px solid @dx-gray-lightest;
        }
        &__highlighted {
            background-color: @dx-blue;
        }
        &__row {
            &:hover {
                background-color: @dx-gray-lightest !important;
            }
            min-width: min-content;
            display: inline-flex;
            flex-direction: row;
            line-height: 3;
        }
        &__body {
            min-width: min-content;
            flex-direction: column;
            display: inline-flex;
            border-bottom: 1px solid @dx-gray-lighter;
            input {
                text-align: right;
            }
        }
        &__child {
            overflow: auto;
            .treeview__child {
                .treeview__body {
                    background-color: #d1dfea;
                }
            }
            .treeview__headers {
                background-color: @dx-gray-lightest;
                padding-left: 10px;
            }
            .treeview__body {
                padding-left: 10px;
                background-color: #f7f9fa;
            }
        }
        &__info {
            background-color: @dx-gray-lightest;
            padding: 10px;
        }
    }

    .fa {
        &.fa-trash-o {
            color: red;
        }
        &.fa-chevron-down {
            font-size: 10px;
            color: @dx-black-medium;
        }
        &.fa-chevron-right {
            font-size: 10px;
            color: @dx-black-medium;
        }
    }
    .context-menu-icon {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;
        &:hover {
            background-color: @dx-gray-lighter;
        }
    }
}
