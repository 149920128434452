.components-input {
    &.type-datetime {
        input[type='time']::-webkit-clear-button {
            display: none;
        }
        input[type='time']::-ms-clear {
            display: none;
        }
    }
    width: 100%;

    .dx-label {
        font-size: 14px;
        font-weight: 600;
        a {
            padding-left: 5px;
            font-size: 12px;
        }
    }

    input,
    select,
    textarea {
        border: none;
        outline: none;
        box-shadow: none;
        background: none;
        width: 100%;
        font-size: 16px;
        font-weight: 500;

        &[type='radio'] {
            width: auto;
            margin-right: 10px;
        }
    }

    textarea {
        resize: vertical;
    }

    // -------------------------------------------------------------------
    // Hides the spinners on input type number in both webkit and mozilla
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
    //--------------------------------------------------------------------

    &:hover {
        // input, select, textarea { cursor: pointer; }
    }
    // &.has-focus {
    // 	input, select, textarea { cursor: auto; }
    // }
    //
    &.is-disabled {
        input,
        select,
        textarea {
            cursor: not-allowed;
            color: #999;
            opacity: 1;
            -webkit-text-fill-color: #999;
        }
    }

    &.header {
        input,
        select,
        textarea {
            font-size: 24px;
        }
    }

    select {
        background: none;
        // removes the padding-of text which makes the chosen value not align with label
        // Also removes the arrow indicating that it is a select box, tho... So I might reconsider this
        -webkit-appearance: initial;
        // margin-left: -7px; // Negates the padding caused by webkit-appearance - but fucks it up in firefox
    }

    padding-bottom: 19px;

    .inputWrapper {
        // border-bottom: 1px solid @dx-gray-lightest;
        border-bottom: 1px solid #d8d8d8;
        // border-bottom: 1px solid transparent;
        margin-bottom: 1px;
        position: relative;
        //background-color: white; // Trying out bg color to make it stand out more on gray backgrounds

        // .dx-dark-header & {
        // 	border-bottom-color: @dx-gray-darker;
        // }
        .input-prefix-wrapper {
            display: flex;
            word-break: normal;
            .input-prefix {
                color: @dx-gray-darker;
                align-self: center;
                margin: 0 4px;
            }
        }
    }

    &:hover {
        .inputWrapper {
            // border-bottom-color: @dx-gray-lighter;
            border-bottom-color: #b1b1b1;
        }
    }

    &.has-focus {
        .inputWrapper {
            border-bottom: 2px solid @dx-secondary;
            margin-bottom: 0;
        }
    }

    &.has-error {
        .inputWrapper {
            border-bottom-color: red;
        }
    }

    &.type-radio {
        // No border under radio button list:
        .inputWrapper {
            border-bottom-color: transparent;
        }
    }

    &.type-duration {
        padding-bottom: 34px;
    }

    &.no-padding {
        padding-bottom: 0;
    }

    .input-message {
        font-size: 12px;

        // If the message is only one line, we don't want it to push other
        // things down.
        margin-bottom: -12px * @line-height-base;
        // will be nice when we can write 100lh (100% line height), but the lh
        // unit is not supported in any browsers yet.
    }
}

.component-input-checkbox {
    @size: 18px;
    // padding-top: 30px;
    // height: 69px;
    line-height: @size;
    cursor: pointer;
    white-space: nowrap; // To keep it from looking bad and folding in on itself
    // To keep it from spanning the whole line (which makes it possible to check
    // and uncheck when clicking way outside the visual limits of the checkbox)
    display: table;

    .checkboxController {
        width: @size;
        height: @size;
        display: inline-block;
        border: 1px solid @dx-gray-darker;
        border-radius: 2px;
        text-align: center;
        color: white;

        vertical-align: middle;

        &.checked {
            background-color: @dx-secondary;
            border: none;
        }
    }

    &:hover {
        .checkboxController:not(.checked) {
            background-color: @dx-gray-lighter;
        }
    }
    .input-message {
        font-size: 12px;

        // If the message is only one line, we don't want it to push other
        // things down.
        //margin-bottom: -12px * @line-height-base;
        // will be nice when we can write 100lh (100% line height), but the lh
        // unit is not supported in any browsers yet.
        white-space: normal;
    }

    label {
        .dx-label;
        user-select: none;
        margin-left: 10px;
        vertical-align: top;
        padding-top: 1px;
        color: #333;
        cursor: pointer;
        white-space: normal;
    }

    &.disabled {
        cursor: not-allowed;

        .checkboxController {
            border: 1px solid @dx-gray-darker;

            text-align: center;
            color: white;

            &.checked {
                background-color: @dx-gray;
                border: none;
            }
        }

        label {
            cursor: not-allowed;
        }
    }
}
