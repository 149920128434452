//configured to be inside layout-with-sidebar-content
.report-viewer-bar {
    display: flex;
    justify-content: space-between;
    position: sticky;
    background-color: white;
    border-radius: 2px;
    padding: 1rem;
    .paper-shadow-bottom-z-2-inset;
    //needs to go in front of iframe, transform property of iframe causes it to overlay
    z-index: 1;

    //invalidates padding-top of layout-with-sidebar-content
    top: -20px;
    @media @dx-mobile-sm {
        top: 0;
    }
}
.report-viewer-container {
    .container-fluid;
    padding-left: 0;
    padding-right: 0;
    .paper-shadow-bottom-z-1;
    max-width: 970px;
    transition: 800ms transform cubic-bezier(0.11, 0.79, 0.22, 1.01), 250ms opacity cubic-bezier(0.28, 1.18, 1, 1);
    background-color: #bfc2cb;
    overflow: hidden;
}

.report-viewer-container-loading {
    opacity: 0 !important;
    transform: translateY(32px);
}

.report-viewer-iframe-container {
    overflow: hidden;
    transform: translateY(0px);
    transition: 800ms all cubic-bezier(0.11, 0.79, 0.22, 1.01);
}

.report-viewer-iframe-container-loading {
    transform: translateY(80vh);
    opacity: 0;
}
