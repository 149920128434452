.reports-table {
    // To make table within not break out of container/panel
    // display: inline-block;
    // min-width: 100%;

    .reports-heading {
        background-color: white;

        display: flex;
        justify-content: space-between;

        @media @dx-mobile-sm {
            // To make sure button is visible for very small screens where table data overflow
            // flex-direction: column;

            h4 {
                font-size: 16px;
            }
        }
    }

    @media @dx-mobile-sm {
        border-left: 0;
        border-right: 0;

        .panel-heading {
            padding: 5px 10px;
        }

        .panel-body {
            padding: 5px;
        }

        .table > thead > tr > th {
            vertical-align: top;
            font-size: 12px;
        }

        .table > thead > tr > th,
        .table > tbody > tr > th,
        .table > tfoot > tr > th,
        .table > thead > tr > td,
        .table > tbody > tr > td,
        .table > tfoot > tr > td {
            padding: 8px 5px;
        }
    }
}
