.zone-editor {
    display: flex;
    justify-content: space-between;

    &__seatmap {
        flex: 0 1 100%;
        padding: 15px 15px 15px 0;
        svg {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
    &__zones {
        flex: 1 0 220px;
        border-left: 1px solid #ccc;
        padding: 15px 0 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        min-height: 0;
        > * {
            margin: 10px 0;
            flex: 0 1 auto;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__zone {
        border-left: 8px solid;
        &.paper-container {
            padding: 0;
        }
        p {
            margin: 0;
        }
    }
    &__zone-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 5px;
    }
    &__zone-actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
    &__zone-action-button {
        width: 23px;
        height: 23px;
        margin-left: 5px;
        border-radius: 50%;
        border: none;
        outline: none;
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        background-color: @dx-blue;
        color: white;
        &:disabled {
            background-color: transparent;
        }
    }
    &__zone-config {
        padding: 0 5px 0 5px;
        height: 0;
        overflow: hidden;
        transition: height 0.3s @dx-default-curve;
        display: flex;
        justify-content: space-between;
        min-width: 0;
        flex-wrap: wrap;
        > p {
            flex: 0 0 100%;
            margin-top: 10px;
            margin-bottom: 5px;
        }
        .components-input {
            flex: 0 0 100%;
            padding-bottom: 0;
        }
        .btn-group {
            flex: 0 1 auto;
        }
        * {
            opacity: 0;
            transition: opacity 0.2s @dx-default-curve;
            transition-delay: 0.2s;
        }
        .open & {
            height: 135px;
            padding: 0 5px 5px 5px;
            overflow: visible;
            * {
                opacity: 1;
            }
        }
    }
    &__zone-seat-count {
        padding: 5px;
        position: relative;
        cursor: pointer;
        color: #999;
        .caret {
            color: @dx-black;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 0.3s @dx-curve-default;
            .open & {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}
