.hide-pager .griddle-footer {
    display: none;
}

.dx-table {
    // In griddle tables, there are no way to disable sorting on columns for now.
    // So we just assume that all are sortable:
    thead th {
        cursor: pointer;
        &:hover {
            // Only the text, not the arrow span
            span:first-child {
                text-decoration: underline;
            }
        }
    }

    &.dx-table-clickable-rows {
        tbody tr {
            cursor: pointer;
            &:hover {
                background-color: @dx-gray-lightest !important;
            }
        }
    }

    .title-column {
        margin: 0;
        padding: 10px 10px 8px 10px !important; // To override griddle default
        min-height: 40px;
        font-weight: bold;
        font-size: 14px;
        vertical-align: middle;
        @media @dx-mobile-sm {
            padding: 8px 10px 5px 0px;
            font-size: 13px;
        }
    }

    .date-column {
        font-size: 13px;
        margin: 0;
    }
}

.economics {
    table {
        border: 2px solid #aaa;

        td:last-child {
            text-align: right;
        }
    }
}

.dx-table {
    .dx-table-defaults;
    border-color: gray;

    tbody {
        width: 100%;
        tr {
            border-bottom: 1px solid @dx-gray-lighter;
            width: 100%;
        }

        tr:nth-child(odd) {
            background-color: @dx-table-row-odd;
        }

        tr:last-child {
            border: none;
        }
    }

    td {
        @td-padding-top: 5px;
        @td-padding-bottom: 4px;

        padding: @td-padding-top 15px @td-padding-bottom 15px;

        @media @dx-mobile-sm {
            padding: 2px 5px 2px 8px;
            font-size: 10px;
        }

        &.editor {
            padding: 0;

            input {
                width: calc(~'100% - 15px');
                height: 100%;
                display: block;
                border: none;
                outline: none;
                background: none;
                margin-left: 9px;
                padding: (@td-padding-top - 1) 0 (@td-padding-bottom - 1) 5px;
                overflow: visible;

                &:focus {
                    border: 1px solid @dx-secondary;
                    border-radius: 2px;
                }
            }
        }

        &[role='button']:hover {
            color: #aaa;
        }
    }

    thead {
        border-bottom: 2px solid @dx-gray-lighter;

        th {
            padding: 10px 15px 8px 15px;
            color: @text-color;

            // This is for old Backgrid tables:
            &.sortable {
                cursor: pointer;
            }

            a {
                color: @text-color;
            }

            @media @dx-mobile-sm {
                padding: 10px 0 8px 10px;
                font-size: 11px;
            }

            &.ascending:after,
            &.descending:after {
                .fa-icon();
                content: @fa-var-chevron-up;
                vertical-align: text-top;
                margin-left: 10px;
                color: @dx-gray-dark;
                font-size: 0.9em;
            }

            &.descending:after {
                content: @fa-var-chevron-down;
            }
        }
    }
}

// Want griddle tables to look like dx-table
.griddle table:extend(.dx-table all) {
}

.griddle-pagination {
    text-align: center;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    user-select: none;

    ul {
        display: inline-block;
        *display: inline;
        margin: 5px 0;
        *zoom: 1;

        li {
            display: inline;

            a,
            span {
                float: left;
                width: 30px;
                height: 30px;
                padding: 0;
                line-height: 30px;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }

        .active {
            a,
            span {
                color: #999999;
                cursor: default;
            }
        }

        .disabled {
            a,
            span,
            a:hover {
                color: #999999;
                cursor: default;
            }
        }
    }
}

.griddle-footer-container {
    background-color: white;
}

.griddle-footer {
    text-align: center;
    padding-top: 20px;

    .griddle-previous,
    .griddle-next,
    .griddle-page {
        display: inline-block;
        button {
            .dx-border-radius;
            .btn;
            color: @dx-gray-darkest;
            background-color: white;
            border-color: @dx-gray;
            border-width: 1px;
            &:hover,
            &:focus {
                outline: 0;
                color: @dx-gray-darkest;
                background-color: @dx-gray-lighter;
                border-color: @dx-gray-dark;
            }
        }

        select {
            height: 34px;
            background-color: white;
            border: 1px solid @dx-gray;
            .dx-border-radius;
            outline: none;
            cursor: pointer;

            &:focus {
                border: 1px solid @dx-secondary;
                cursor: text;
            }
        }
    }

    .griddle-previous {
        margin-right: 10px;
    }

    .griddle-next {
        margin-left: 10px;
    }
}
.table {
    &.migrations {
        tbody {
            tr {
                background-color: #fff;
                &:nth-child(odd) {
                    background-color: #fafafa;
                }
                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
