.dx-popover {
    position: absolute;
    top: -120%;
    left: -5%;
    background-color: white;
    color: @dx-text;
    font-size: 14px;
    z-index: 3;
    border: 1px solid @dx-gray-darker;
    border-radius: 2px;
    // white-space: nowrap;
    // max-width: 200px;
    max-width: 100%;
    padding: 3px 10px;

    &:after,
    &:before {
        position: absolute;
        top: 100%;
        left: 25%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 254, 252, 0);
        border-top-color: #fff;
        border-width: 5px;
        margin-left: -5px;
    }

    &:before {
        border-color: rgba(194, 225, 245, 0);
        border-top-color: @dx-gray-darker;
        border-width: 6px;
        margin-left: -6px;
    }
}

.dx-popover-blue {
    border: 1px solid @dx-secondary;
    &:before {
        border-top-color: @dx-secondary;
    }
}

.dx-popover-gray {
    border: 1px solid @dx-gray-darker;
    &:before {
        border-top-color: @dx-gray-darker;
    }
}

.dx-popover-red {
    border: 1px solid @dx-danger;
    &:before {
        border-top-color: @dx-danger;
    }
}

.dx-popover-green {
    border: 1px solid @dx-primary;
    &:before {
        border-top-color: @dx-primary;
    }
}

.dx-popover-orange {
    border: 1px solid @dx-warning;
    &:before {
        border-top-color: @dx-warning;
    }
}
