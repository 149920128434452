.mail-popup-container {
    .mail-header {
        padding: 0 20px 20px 20px;
        .has-error {
            border-bottom-color: red;
        }
        input:invalid {
            border-bottom-color: red;
        }
        input {
            border: 0;
            outline: 0;
            background: transparent;
            border-bottom: 0 solid #d9d9d9;
            width: 100%;
        }
        &__receivers {
            display: flex;
            //flex-wrap: wrap;
            padding-bottom: 10px;
            &-border {
                border-bottom: 1px solid @dx-gray-lighter;
                padding-bottom: 5px;
            }
            &-border-error {
                border-bottom: 1px solid red;
                padding-bottom: 5px;
            }
            i {
                font-size: 12px;
            }
            .mail-header__receivers-multi-email-input-emails {
                padding-left: 30px;
                width: 100%;
            }
            .input-prefix-wrapper {
                display: flex;
                flex-grow: 2;
            }
            &-multi-email-input {
                display: flex;
                flex-grow: 2;

                &-emails {
                    display: flex;
                    flex-wrap: wrap;
                    //padding-left: 30px;
                    .email-address {
                        background-color: rgba(0, 126, 255, 0.08);
                        color: #007eff;
                        border: 1px solid #d9d9d9;
                        cursor: default;
                        display: block;
                        height: 30px;
                        white-space: nowrap;
                        border-radius: 3px;
                        margin-right: 5px;

                        &__label {
                            display: inline-block;
                            color: #007eff;
                            margin: 2px 5px;
                            max-width: 325px;
                            max-height: 17px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            direction: ltr;
                            vertical-align: text-top;
                        }
                        &__remove {
                            display: inline-block;
                            width: 14px;
                            height: 20px;
                            opacity: 0.6;
                            cursor: pointer;
                            vertical-align: text-top;
                            padding: 2px 0 0 4px;
                            i {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            &-collapse {
                display: none;
            }
            &-collapse.in {
                display: block;
                .mail-input__copy {
                    display: flex;
                    padding: 10px 0 10px 0;
                    &-border {
                        border-bottom: 1px solid @dx-gray-lighter;
                        padding-bottom: 5px;
                    }
                    &-border-error {
                        border-bottom: 1px solid red;
                        padding-bottom: 5px;
                    }
                    .mail-header__receivers-multi-email-input-emails {
                        padding-left: 30px;
                    }
                    .input-prefix-wrapper {
                        display: flex;
                        flex-grow: 2;
                    }
                }
                .mail-input__copy {
                    display: flex;
                    padding: 10px 0 10px 0;
                    &-border {
                        border-bottom: 1px solid @dx-gray-lighter;
                        padding-bottom: 5px;
                    }
                    &-border-error {
                        border-bottom: 1px solid red;
                        padding-bottom: 5px;
                    }
                    .mail-header__receivers-multi-email-input-emails {
                        padding-left: 18px;
                        width: 100%;
                    }
                    .input-prefix-wrapper {
                        display: flex;
                        flex-grow: 2;
                    }
                }
                .mail-input__blind-copy {
                    display: flex;
                    padding: 10px 0 10px 0;
                    &-border {
                        border-bottom: 1px solid @dx-gray-lighter;
                        padding-bottom: 5px;
                    }
                    &-border-error {
                        border-bottom: 1px solid red;
                        padding-bottom: 5px;
                    }
                    .mail-header__receivers-multi-email-input-emails {
                        padding-left: 8px;
                        width: 100%;
                    }
                    .input-prefix-wrapper {
                        display: flex;
                        flex-grow: 2;
                    }
                }
            }
        }
        &__expand-collapse {
            justify-content: flex-end;
            opacity: 0.6;
        }

        &__subject {
            padding-top: 20px;
        }
    }
    .mail-body {
        padding: 0 20px 20px 20px;
    }
    .mail-attachment {
        padding: 0 20px 10px 20px;
        display: inline-block;
        border-radius: 2px; //@dx-border-radius;
        &__body {
            padding: 0 20px 0 20px;
            border-radius: 2px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            &-content {
                display: flex;
                padding: 10px 0 10px 0;
                .pdf {
                    font-size: 30px;
                    color: Tomato;
                }
                .file-name {
                    font-weight: bold;
                    padding: 10px 0 0 10px;
                }
            }
        }
    }
    .multi-email-input {
        &-border {
            border-bottom: 1px solid @dx-gray-lighter;
            padding: 10px;
        }
    }
}
