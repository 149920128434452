.orders-module {
    .empty-state-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 200px;

        .center {
            flex: 0 0 40rem;
            width: 40rem;
            text-align: center;

            @media @dx-mobile-xs {
                width: 95%;
                flex: 0 0 95%;
            }

            h1 {
                margin-top: 10rem;
                font-weight: 100;
                font-size: 2.4rem;

                @media @dx-mobile-xs {
                    margin-top: 4rem;
                }
            }

            .btn {
                background-color: @dx-primary;
                border-color: @dx-primary;
                margin: 3rem 0 3rem 0;
            }

            .description-block {
                display: flex;
                flex-direction: row;

                .left {
                    flex: 0 0 auto;

                    img {
                        float: left;
                        margin-right: 2rem;
                    }
                }

                .right {
                    flex: 1 1 auto;

                    h2 {
                        font-size: 2rem;
                        margin-top: 0.5rem;
                    }

                    h2,
                    p {
                        text-align: left;
                    }
                }

                margin: 4rem 0 4rem 0;
            }
        }
    }
}
