/*
 *	ListPartners
 */

table.partners-list {
    td:nth-child(1) {
        width: 2%;
    }
    td:nth-child(2) {
        width: 1%;
    }
    td:nth-child(3) {
        width: 67%;
    }
    td:nth-child(4) {
        width: 30%;
    }
    td:nth-child(4) {
        width: 15%;
    }
    td:nth-child(5) {
        width: 15%;
    }

    // #HACK to make the columns marked not sortable
    thead {
        th:nth-child(1),
        th:nth-child(5) {
            pointer-events: none;
        }
    }

    p.partners-list-name-cell {
        vertical-align: middle;
        margin: 0;
        font-weight: bold;
        font-size: 14px;
        padding: 10px 10px 8px 0px;
        min-height: 40px;
        @media @dx-mobile-sm {
            padding: 8px 10px 5px 0px;
            font-size: 13px;
        }
    }

    span.partners-list-active,
    span.partners-list-deactivated {
        color: @dx-primary;
        font-size: 18px;
        padding-left: 5px;
    }
    span.partners-list-deactivated {
        color: @dx-gray;
    }
}

/*
 *	EditPartner
 */

table.partner-modules-list {
    td:nth-child(1) {
        width: 40%;
    }
    td:nth-child(2) {
        width: 35%;
    }
    td:nth-child(3) {
        width: 20%;
    }
    td:nth-child(4) {
        width: 5%;
    }

    @media @dx-mobile-md {
        td:nth-child(1) {
            width: 60%;
        }
        td:nth-child(2) {
            width: 20%;
        }
        td:nth-child(3) {
            width: 15%;
        }
        td:nth-child(4) {
            width: 5%;
        }
    }
    thead {
        th:nth-child(2),
        th:nth-child(4) {
            pointer-events: none;
        }
    }
}

.partner-module-name-cell {
    .indentation {
        display: inline-block;
        width: 60px;
        height: 30px;
        @media @dx-mobile-sm {
            width: 20px;
            height: 30px;
        }
    }

    .module-image {
        display: inline-block;
        margin-right: 15px;
        vertical-align: top;
        font-size: 40px;
        @media @dx-mobile-sm {
            margin-right: 6px;
        }

        img {
            width: 40px;
            height: 40px;
            // For now until we work out the final design of icon, just to make the white icons visible on white
            background-color: @dx-text;
            padding: 2px;
            border-radius: 30px;
            ///////////////////////////
            @media @dx-mobile-sm {
                width: 25px;
                height: 25px;
                margin-top: 5px;
            }
        }
    }

    .module-name {
        display: inline-block;
        p {
            vertical-align: middle;
            margin: 0;
            padding: 0;
            padding-top: 17px;
            font-weight: bold;
            font-size: 14px;
            @media @dx-mobile-sm {
                font-size: 13px;
            }
        }
    }
}

table.partner-modules-add-list {
    td:nth-child(1) {
        width: 50%;
    }
    td:nth-child(2) {
        width: 40%;
    }
    td:nth-child(3) {
        width: 10%;
    }

    @media @dx-mobile-sm {
        td:nth-child(1) {
            width: 70%;
        }
        td:nth-child(2) {
            width: 20%;
        }
        td:nth-child(3) {
            width: 10%;
        }
    }

    thead {
        th:nth-child(3) {
            pointer-events: none;
        }
    }
}

table.partner-ebillett-users-list {
    td:nth-child(1) {
        width: 35%;
    }
    td:nth-child(2) {
        width: 60%;
    }
    td:nth-child(3) {
        width: 5%;
    }
    thead {
        th:nth-child(2),
        th:nth-child(4) {
            pointer-events: none;
        }
    }
}

table.partner-users-list {
    td:nth-child(1) {
        width: 10%;
    }
    td:nth-child(2) {
        width: 30%;
    }
    td:nth-child(3) {
        width: 20%;
    }
    td:nth-child(4) {
        width: 20%;
    }
    td:nth-child(5) {
        width: 10%;
    }
    td:nth-child(6) {
        width: 10%;
    }

    thead {
        th:nth-child(1),
        th:nth-child(4),
        th:nth-child(6) {
            pointer-events: none;
        }
    }

    @media @dx-mobile-sm {
        td:nth-child(1) {
            width: 40%;
        }
        td:nth-child(2) {
            width: 25%;
        }
        td:nth-child(3) {
            width: 25%;
        }
        td:nth-child(4) {
            width: 10%;
        }
        thead {
            th:nth-child(2),
            th:nth-child(4) {
                pointer-events: none;
            }
        }
    }
}

.partner-edit-terms-link-wrapper {
    padding: 10px 3px;
}

.partner-edit-terms-header {
    font-weight: 400;
    // margin-top: 60px;
    font-size: 16px;
}

.partner-edit-terms {
    border: 1px solid @dx-gray-lighter;
    border-radius: 2px;
    border-bottom: none;
    margin-bottom: -1px;

    div.term-rows {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        div.term-col {
            flex: 0 0 16%;
            padding: 5px 0;

            p {
                margin: 0;
                font-size: 14px;
            }

            strong {
                font-size: 16px;
                display: inline-block;
                margin-right: 5px;
                margin: 0 5px 0 10px;
            }

            p.valid-date {
                font-size: 14px;
                color: #888;
                font-weight: 200;
                margin-left: 10px;
            }

            @media @dx-mobile-xs {
                padding: 2px 0;

                p {
                    font-size: 11px;
                }
                strong {
                    font-size: 13px;
                    margin: 0 3px 0 3px;
                }
                p.valid-date {
                    font-size: 10px;
                    margin-left: 3px;
                }
            }
        }
    }

    div.term-rows:last-child {
        border-bottom: 1px solid @dx-gray-lighter;
        margin-bottom: 0;
    }

    i.partner-edit-terms-icon {
        font-size: 12px;
        vertical-align: text-top;
        font-style: normal;
        color: @dx-secondary;
    }
}

div.term-rows-header {
    display: flex;
    flex-flow: row nowrap;

    div.term-col {
        flex: 0 0 16%;

        p {
            margin: 0;
            font-weight: 600;
            font-size: 14px;
        }

        @media @dx-mobile-xs {
            p {
                font-size: 9px;
            }
        }
    }

    @media @dx-mobile-xs {
        align-items: right;
    }
}

div.term-rows-header-with-padding-bottom {
    div.term-col {
        padding-bottom: 5px;
    }
}
