.input-location-container {
    position: relative;
    z-index: 1;

    .input-location {
        // position: absolute;
        // top: 0;

        -webkit-transform: scaleY(1);
        -o-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);

        &.hidden {
            -webkit-transform: scaleY(0);
            -o-transform: scaleY(0);
            -ms-transform: scaleY(0);
            transform: scaleY(0);

            -webkit-transform-origin: top;
            -o-transform-origin: top;
            -ms-transform-origin: top;
            transform-origin: top;

            -webkit-transition: -webkit-transform 2s ease-out;
            -o-transition: -o-transform 2s ease;
            -ms-transition: -ms-transform 2s ease;
            transition: transform 2s ease;
        }
    }
}
