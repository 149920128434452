.focal-point-selector {
    .zoom-selector {
        -webkit-appearance: none;
        width: 100%;
        height: 7px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        max-width: 450px;
    }
    .zoom-selector:hover {
        opacity: 1;
    }
    .focal-image-container {
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        .focal-image-inner-container {
            position: relative;
            display: inline-block;
        }
    }
}
