@button-blue: @dx-blue;
@button-blue-hover: #047dce;
@button-blue-disabled: #89d1ff;

@button-red: @dx-red;
@button-red-hover: #bf170f;
@button-red-disabled: #f49f9c;

@button-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
@button-box-shadow-focus: 0 3px 4px rgba(0, 0, 0, 0.3);

.dx-button {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 3px;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 2px solid transparent;
    white-space: nowrap;
    padding: 5px 11px;
    font-size: 16px;
    line-height: 1.42857143;
    border-radius: 2px;
    user-select: none;
    color: @dx-white;
    box-shadow: @button-box-shadow;
    &:focus {
        box-shadow: @button-box-shadow-focus;
    }
    &[disabled] {
        cursor: default;
    }
    & > i {
        padding-right: 3px;
    }
    &--primary {
        background-color: @button-blue;
        border-color: @button-blue;
        &:hover,
        &:focus {
            background-color: @button-blue-hover;
            border-color: @button-blue-hover;
            color: @dx-white;
            text-decoration: none;
        }
        &[disabled] {
            &,
            &:hover,
            &:focus {
                background-color: @button-blue-disabled;
                border-color: @button-blue-disabled;
            }
        }
    }
    &--secondary {
        color: @button-blue;
        border-color: @button-blue;
        background-color: @dx-white;
        &:hover,
        &:focus {
            color: @button-blue-hover;
            border-color: @button-blue-hover;
            text-decoration: none;
        }
        &[disabled] {
            &,
            &:hover,
            &:focus {
                color: @button-blue-disabled;
                border-color: @button-blue-disabled;
            }
        }
    }
    &--delete {
        background-color: @button-red;
        border-color: @button-red;
        &:hover,
        &:focus {
            background-color: @button-red-hover;
            border-color: @button-red-hover;
            color: @dx-white;
            text-decoration: none;
        }
        &[disabled] {
            &,
            &:hover,
            &:focus {
                background-color: @button-red-disabled;
                border-color: @button-red-disabled;
            }
        }
    }
}
