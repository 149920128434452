@hover-color: #ccc;
@border-color: #ccc;

.mode-reports-container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    .mode-reports-list {
        border: 1px solid @border-color;
        .mode-reports-list-item {
            border-top: 1px solid @border-color;
            &:first-child {
                border-top: none;
            }
            &:hover {
                background-color: @hover-color;
                cursor: pointer;
            }
            p {
                margin: 0;
                padding: 20px;
            }
        }
    }
}
