/*
 *    Last cleanup: July 1st 2016
*/

.crm-export-dialog {
    .num-records {
        font-weight: bold;
    }

    p {
        font-size: 1.6em;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    button {
        .btn;
        .btn-link;

        width: 228px;
        height: 186px;

        margin-top: 15px;

        @media @dx-mobile-md {
            width: (228px/2px);
            height: (186px/2px);
        }

        box-sizing: initial;

        padding: 0;
        border-radius: 2px;

        &.export-excel-btn {
            background: url('/app/desktop/src/assets/crm/export-excel.png') no-repeat center;
            @media @dx-mobile-md {
                background-image: url('/app/desktop/src/assets/crm/export-excel@0,5x.png');
            }
        }

        &.export-csv-btn {
            background: url('/app/desktop/src/assets/crm/export-csv.png') no-repeat center;
            @media @dx-mobile-md {
                background-image: url('/app/desktop/src/assets/crm/export-csv@0,5x.png');
            }
        }

        &.export-txt-btn {
            background: url('/app/desktop/src/assets/crm/export-txt.png') no-repeat center;
            @media @dx-mobile-md {
                background-image: url('/app/desktop/src/assets/crm/export-txt@0,5x.png');
            }
        }

        &:hover {
            background-color: #eee;
        }
    }
}

.module-crm {
    .input-group {
        width: 100%;
    }

    .dx-btn {
        margin-left: 0;
    }

    .no-results-view {
        margin-top: 75px;

        .justify-content {
            justify-content: center;
        }

        @media @dx-mobile-sm {
            margin-top: 30px;
        }

        h1 {
            color: @dx-gray-dark;
            font-size: 30px;

            @media @dx-mobile-sm {
                font-size: 26px;
            }
        }

        .maskot-container {
            margin-right: 20px;
            @media @dx-mobile-sm {
                margin: 0;
                width: 100%;
                text-align: center;
            }
        }

        .subtitle {
            font-weight: bold;
        }
    }

    .dx-table {
        table-layout: fixed;

        td,
        th {
            padding: 7px;

            &:nth-child(1) {
                width: 25%;
            }
            &:nth-child(2) {
                width: 35%;
            }
            &:nth-child(3) {
                width: auto;
            }
            &:nth-child(4) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 10%;
            }
            box-sizing: border-box;

            &:last-child {
                text-align: right;
            }
            &:nth-child(4) {
                text-align: center;
            }
        }
    }

    .info .stat {
        span {
            margin-right: 20px;
        }

        b {
            font-weight: 100;
            margin-right: 10px;
        }
    }
}
