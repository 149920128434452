.exportable-report-page {
    .paper-shadow-bottom-z-1;
    &__toolbar {
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: -20px;
        background-color: white;
        border-radius: 2px;
        padding: 1rem;
        .paper-shadow-bottom-z-2-inset;
        z-index: 1;
    }
    &__toolbar--tools {
        display: flex;
        align-items: center;
        .loading-indicator {
            display: inline-block;
            i {
                margin-right: 5px;
            }
            p {
                margin: 0 15px 0 0;
            }
        }
    }
}
